import classNames from "classnames";
import React, { useContext } from "react";
import {
  AccordionContext,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  useAccordionToggle,
} from "react-bootstrap";
import styled, { css } from "styled-components";
import ClickableContainer from "./ClickableContainer";
import DeprecatedH3 from "components/deprecated/DeprecatedH3";
import DeprecatedH6 from "components/deprecated/DeprecatedH6";

const MOBILE_NAV_TOGGLE_SPAN_HEIGHT = 40; // px
const MobileNavToggleSpan = styled.span`
  display: flex;
  padding-right: 12px;
  align-items: center;
  height: ${MOBILE_NAV_TOGGLE_SPAN_HEIGHT}px;
  line-height: ${MOBILE_NAV_TOGGLE_SPAN_HEIGHT}px;
  width: 100%;
`;

type MobileNavToggleProps = {
  className?: string;
  children: React.ReactNode;
  callback?: (eventKey: string) => void;
  eventKey: string;
  isDisabled?: boolean;
};

export const MobileNavToggle = ({
  className,
  children,
  eventKey,
  callback,
  isDisabled,
}: MobileNavToggleProps) => {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
  if (isDisabled) {
    return <MobileNavToggleSpan className={className}>{children}</MobileNavToggleSpan>;
  }
  let accordionClassName = currentEventKey === eventKey ? "accordion-show" : "accordion-collapse";
  if (className) {
    accordionClassName = `${className} ${accordionClassName}`;
  }

  return (
    <MobileNavToggleSpan className={accordionClassName} onClick={decoratedOnClick}>
      {children}
    </MobileNavToggleSpan>
  );
};

const LeftNavToggleSpan = styled.span<{ $rowHeight: number; $isTruncatable: boolean }>`
  display: flex;
  padding: 0 24px;
  align-items: center;
  width: 100%;
  ${({ $rowHeight }) => css`
    height: ${$rowHeight}px;
    line-height: ${$rowHeight}px;
  `}
  ${({ $isTruncatable }) =>
    $isTruncatable &&
    css`
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 8px 0 24px;
    `}
`;

type LeftNavToggleProps = {
  children: React.ReactNode;
  eventKey: string;
  callback?: (eventKey: string) => void;
  isDisabled?: boolean;
  rowHeight: number;

  /**
   * If the contents should truncate instead of display as flex
   */
  isTruncatable?: boolean;
};

export const LeftNavToggle = ({
  children,
  eventKey,
  callback,
  isDisabled,
  rowHeight,
  isTruncatable,
}: LeftNavToggleProps) => {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
  if (isDisabled) {
    return (
      <LeftNavToggleSpan $rowHeight={rowHeight} $isTruncatable={isTruncatable ?? false}>
        {children}
      </LeftNavToggleSpan>
    );
  }
  const className = currentEventKey === eventKey ? "accordion-show" : "accordion-collapse";
  return (
    <LeftNavToggleSpan
      $rowHeight={rowHeight}
      $isTruncatable={isTruncatable ?? false}
      className={className}
      onClick={decoratedOnClick}
    >
      {children}
    </LeftNavToggleSpan>
  );
};

type CardHeaderToggleProps = {
  callback?: (eventKey: string) => void;
  eventKey: string;
  category: string;
  enabledIntegrationsCount: number;
  categoryIntegrationsCount: number;
};

export const CardHeaderToggle = ({
  eventKey,
  callback,
  category,
  enabledIntegrationsCount,
  categoryIntegrationsCount,
}: CardHeaderToggleProps) => {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
  const isCurrentEventKey = currentEventKey === eventKey;
  const allText = enabledIntegrationsCount === categoryIntegrationsCount && "All";

  return (
    <Card.Header className="clickable-card" onClick={decoratedOnClick}>
      <Row>
        <Col>
          <DeprecatedH3 className="mb-1 ml-1">{category.toUpperCase()}</DeprecatedH3>
        </Col>
        <Col className="text-right text-muted small">
          {allText ?? enabledIntegrationsCount === 0 ? "0" : enabledIntegrationsCount} enabled
        </Col>
        <Col className="col-auto pl-0 text-muted small">
          <span
            className={classNames(isCurrentEventKey ? "accordion-show" : "accordion-collapse")}
          />
        </Col>
      </Row>
    </Card.Header>
  );
};

type PlusMinusToggleProps = {
  children: React.ReactNode;
  callback?: (eventKey: string) => void;
  eventKey: string;
};

export const PlusMinusToggle = ({ children, eventKey, callback }: PlusMinusToggleProps) => {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <ClickableContainer>
      <Row onClick={decoratedOnClick}>
        <Col>
          <DeprecatedH6 className="header-pretitle">{children}</DeprecatedH6>
        </Col>
        <Col className="col-auto">
          {isCurrentEventKey ? <span className="fe fe-minus" /> : <span className="fe fe-plus" />}
        </Col>
      </Row>
    </ClickableContainer>
  );
};

type SettingsButtonToggleProps = {
  callback?: (eventKey: string) => void;
  eventKey: string;
};

export const SettingsButtonToggle = ({ eventKey, callback }: SettingsButtonToggleProps) => {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
  const isCurrentEventKey = currentEventKey === eventKey;
  const keyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === "Enter") {
      decoratedOnClick(event);
    }
  };

  return (
    <ClickableContainer>
      {isCurrentEventKey ? (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-top">Collapse settings</Tooltip>}
        >
          <span
            role="button"
            aria-label="collapse"
            tabIndex={0}
            onKeyDown={keyDown}
            className="fe fe-minus"
            onClick={decoratedOnClick}
          />
        </OverlayTrigger>
      ) : (
        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">View settings</Tooltip>}>
          <span
            role="button"
            aria-label="collapse"
            tabIndex={0}
            onKeyDown={keyDown}
            className="fe fe-plus"
            onClick={decoratedOnClick}
          />
        </OverlayTrigger>
      )}
    </ClickableContainer>
  );
};
