import { Link } from "gatsby";
import styled, { css } from "styled-components";

export const TOP_NAV_HEIGHT = 64; // px
export const ROW_HEIGHT = 40; // px

export const NavSectionTitle = styled.div`
  width: 100%;
  padding: 0 16px;
  margin: 8px 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #92959e;
`;

export const MobileNavSectionLink = styled(Link)<{ $isActive?: boolean }>`
  && {
    font-size: 14px;
    line-height: ${ROW_HEIGHT}px;
    width: 100%;
    display: flex;
    align-items: center;
    color: var(--gray90) !important;
    padding: 0;
    font-weight: ${({ $isActive }) => ($isActive ? "600" : "400")} !important;
    border-left: ${({ $isActive }) => ($isActive ? "3px solid #121314" : "none")};
    background: ${({ $isActive }) => ($isActive ? "#f4f5f6" : "none")};
    ${({ $isActive }) =>
      $isActive &&
      css`
        margin-left: -3px;
      `};
    &:hover {
      background: #f4f5f6;
      cursor: pointer;
    }
  }
`;

export const AccordionNavLink = styled(MobileNavSectionLink)<{ $isActive?: boolean }>`
  && {
    padding-left: 44px;
    font-size: 13px;
    font-weight: 400;
    border-left: ${({ $isActive }) => ($isActive ? "3px solid #121314" : "none")};
    background: ${({ $isActive }) => ($isActive ? "#f4f5f6" : "none")};
  }
`;

export const NavSectionToggleContainer = styled.div<{ $isActive?: boolean }>`
  && {
    font-size: 14px;
    line-height: ${ROW_HEIGHT}px;
    width: 100%;
    display: flex;
    align-items: center;
    color: #121314 !important;
    padding: 0;
    font-weight: ${({ $isActive }) => ($isActive ? "600" : "400")} !important;
    border-left: ${({ $isActive }) => ($isActive ? "3px solid #121314" : "none")};
    background: ${({ $isActive }) => ($isActive ? "#f4f5f6" : "none")};
    &:hover {
      background: #f4f5f6;
      cursor: pointer;
    }
  }
`;

export const AccordionUL = styled.ul`
  list-style: none;
  padding: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;

  && {
    margin-bottom: 14px;
  }
`;

export const AccordionLI = styled.li`
  width: 100%;
`;

export const FeatherIconSpan = styled.span`
  margin: 0 14px;
`;
