import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

const ExternalBaseLink = styled.a`
  white-space: nowrap;
  text-decoration: none;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  padding: 2px 10px;
  color: var(--gray90) !important;

  &:hover {
    color: #727581 !important;
  }

  &:active {
    color: #727581 !important;
    opacity: 0.5 !important;
  }
`;

// Note that ActiveLink uses negative margins to counter the added padding, so the text stays in the same place.
const ExternalActiveLink = styled.a`
  color: #ffffff !important;
  font-size: 15px;
  font-weight: 600;
  height: 28px;
  border-radius: 6px !important;
  padding: 2px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  background: var(--gray90) !important;

  &:hover {
    color: #ffffff !important;
  }
`;

const InternalBaseLink = ExternalBaseLink.withComponent(Link);
const InternalActiveLink = ExternalActiveLink.withComponent(Link);

interface ChoiceLinkProps {
  children?: JSX.Element | string | null;
  isActive?: boolean;
  title?: string;
  to: string;
  style?: Record<string, unknown>;
}

const ExternalLink = ({ children, isActive, to, title, style }: ChoiceLinkProps) =>
  isActive ? (
    <ExternalActiveLink href={to} title={title} style={style}>
      {children}
    </ExternalActiveLink>
  ) : (
    <ExternalBaseLink href={to} title={title} style={style}>
      {children}
    </ExternalBaseLink>
  );

const InternalLink = ({ children, isActive, to, title, style }: ChoiceLinkProps) => {
  if (isActive) {
    return (
      <InternalActiveLink to={to} title={title} style={style}>
        {children}
      </InternalActiveLink>
    );
  }
  return (
    <InternalBaseLink to={to} title={title} style={style}>
      {children}
    </InternalBaseLink>
  );
};

const ChoiceLink = (props: ChoiceLinkProps) => {
  const { to } = props;
  const isInternalLink = /^\/(?!\/)/.test(to);
  return isInternalLink ? <InternalLink {...props} /> : <ExternalLink {...props} />;
};

export default ChoiceLink;
