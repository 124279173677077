/**
 * This is the base URL of all sub-pages for endpoint mappings.
 */
const ALL_INTEGRATIONS_BASE_URL = "/integrations";

/**
 * Remaps the results of querying `allEndpointMapping` into a map from
 * APICategory -> integration.slug -> EndpointMapping.
 * @param {import("../hooks/useEndpointMappings").QueryType['allEndpointMapping']} allEndpointMapping The results of the Query for allEndpointMapping
 * @returns the internal-only link to the blog post page for this title
 */
const remapAllEndpointMapping = (allEndpointMapping) => {
  /**
   * @type Record<import("../types/types").APICategory, Record<string, import("../types/types").EndpointMapping>>
   */
  const categoryMap = {
    ats: {},
    hris: {},
    accounting: {},
    ticketing: {},
    crm: {},
    mktg: {},
    filestorage: {},
  };

  // Sort by slug
  const sortedMapping = allEndpointMapping.nodes.sort((mappingA, mappingB) =>
    mappingA.integration.slug.localeCompare(mappingB.integration.slug),
  );

  // Take each integration's categories and create entries for the integration's slug
  sortedMapping.forEach(({ integration, endpoints }) => {
    integration.categories.forEach((category) => {
      if (category in categoryMap) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        categoryMap[category][integration.slug] = { integration, endpoints };
      }
    });
  });
  return categoryMap;
};

/**
 * Creates a page URL for a category/integration combo
 * @param {string | null} category
 * @param {import("../types/types").IntegrationWithSlug | null} integration
 * @param {string | null} endpointMappingTab
 * @returns The page url
 */
const pageUrl = (category, integration, endpointMappingTab) => {
  if (!category) {
    return ALL_INTEGRATIONS_BASE_URL;
  }
  if (!integration) {
    return `${ALL_INTEGRATIONS_BASE_URL}/${category}/`;
  }
  if (!endpointMappingTab) {
    return `${ALL_INTEGRATIONS_BASE_URL}/${category}/${integration.slug}/`;
  }
  return `${ALL_INTEGRATIONS_BASE_URL}/${category}/${integration.slug}/${endpointMappingTab}/`;
};

/**
 * This module is JS (not TS) and not using `export` so it can be used in both
 * gatsby-node.js and .ts(x) files
 */
// eslint-disable-next-line no-undef
module.exports = {
  remapAllEndpointMapping,
  pageUrl,
};
