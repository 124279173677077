import React from "react";
import { Link } from "gatsby";
import { APICategory, capitalizeFirstLetter } from "@merge-api/merge-javascript-shared";
import { CategoryInfo, CategoryPublicationStatus } from "../../../types/types";
import { ADDITIONAL_ENDPOINT_INFO_MAP, AdditionalEndpointInfo } from "../assets/constants";

export const getNavLinkNameForCategory = (category: APICategory): string => {
  switch (category) {
    case APICategory.ats:
      return "Recruiting (ATS)";
    case APICategory.hris:
      return "HR, Payroll, and Directory (HRIS)";
    case APICategory.accounting:
      return "Accounting";
    case APICategory.ticketing:
      return "Ticketing";
    case APICategory.crm:
      return "CRM";
    case APICategory.mktg:
      return "Marketing Automation";
    case APICategory.filestorage:
      return "File Storage";
  }
};

export const getCategoryReferenceName = (category: APICategory): string => {
  switch (category) {
    case APICategory.hris:
      return "HR, Payroll, and Directory";
    case APICategory.ats:
      return "Recruiting";
    case APICategory.accounting:
      return "Accounting";
    case APICategory.ticketing:
      return "Ticketing";
    case APICategory.crm:
      return "Customer Relationship Management";
    case APICategory.mktg:
      return "Marketing Automation";
    case APICategory.filestorage:
      return "File Storage";
  }
};

export const featherIconForAPICategory = (category: APICategory): string => {
  switch (category) {
    case APICategory.hris:
      return "users";
    case APICategory.ats:
      return "user-plus";
    case APICategory.accounting:
      return "dollar-sign";
    case APICategory.ticketing:
      return "list";
    case APICategory.crm:
      return "contact";
    case APICategory.mktg:
      return "";
    case APICategory.filestorage:
      return "file";
  }
};

export const displayNameForAPICategory = (category: APICategory | null): string | null => {
  switch (category) {
    case APICategory.ats:
      return "ATS";
    case APICategory.hris:
      return "HR, Payroll, and Directory";
    case APICategory.accounting:
      return "Accounting";
    case APICategory.ticketing:
      return "Ticketing";
    case APICategory.crm:
      return "CRM";
    case APICategory.mktg:
      return "Marketing Automation";
    case APICategory.filestorage:
      return "File Storage";
    default:
      return null;
  }
};

export const apiCategoryFromString = (categoryName: string): APICategory | null => {
  switch (categoryName) {
    case APICategory.hris:
      return APICategory.hris;
    case APICategory.ats:
      return APICategory.ats;
    case APICategory.accounting:
      return APICategory.accounting;
    case APICategory.ticketing:
      return APICategory.ticketing;
    case APICategory.crm:
      return APICategory.crm;
    case APICategory.mktg:
      return APICategory.mktg;
    case APICategory.filestorage:
      return APICategory.filestorage;
    default:
      return null;
  }
};

export const categoryPagePathForCategory = (category: APICategory): string => {
  switch (category) {
    case APICategory.ats:
      return "ats-recruiting-api";
    case APICategory.hris:
      return "hr-payroll-api";
    case APICategory.accounting:
      return "accounting-api";
    case APICategory.ticketing:
      return "ticketing-api";
    case APICategory.crm:
      return "crm-api";
    case APICategory.mktg:
      return "mktg-api";
    case APICategory.filestorage:
      return "filestorage-api";
  }
};

export const categoryPreviewForAPICategory = (category: APICategory): string => {
  switch (category) {
    case APICategory.ats:
      return "applicant tracking";
    case APICategory.hris:
      return "HR";
    case APICategory.accounting:
      return "accounting";
    case APICategory.ticketing:
      return "ticketing";
    case APICategory.crm:
      return "CRM";
    case APICategory.mktg:
      return "Marketing Automation";
    case APICategory.filestorage:
      return "File Storage";
  }
};

export const descriptiveNameForAPICategory = (category: APICategory): string => {
  switch (category) {
    case APICategory.ats:
      return "applicant tracking";
    case APICategory.hris:
      return "HR, payroll, and directory";
    case APICategory.accounting:
      return "accounting";
    case APICategory.ticketing:
      return "project management and ticketing";
    case APICategory.crm:
      return "customer relationship management";
    case APICategory.mktg:
      return "marketing automation";
    case APICategory.filestorage:
      return "file storage";
  }
};

export const fullDisplayNameForAPICategory = (category: APICategory): string => {
  switch (category) {
    case APICategory.ats:
      return "Applicant Tracking System (ATS)";
    case APICategory.hris:
      return "Human Resource Information System (HRIS)";
    case APICategory.accounting:
      return "Accounting";
    case APICategory.ticketing:
      return "Ticketing";
    case APICategory.crm:
      return "Customer Relationship Management (CRM)";
    case APICategory.mktg:
      return "Marketing Automation (MKTG)";
    case APICategory.filestorage:
      return "File Storage";
  }
};

export const docsLinkForAPICategory = (category: APICategory): string => {
  switch (category) {
    case APICategory.ats:
      return "/ats/overview";
    case APICategory.hris:
      return "/hris/overview";
    case APICategory.accounting:
      return "/accounting/overview";
    case APICategory.ticketing:
      return "/ticketing/overview";
    case APICategory.crm:
      return "/crm/overview";
    case APICategory.mktg:
      return "/mktg/overview";
    case APICategory.filestorage:
      return "/filestorage/overview";
  }
};

export const abbreviationForAPICategory = (category: APICategory): string => {
  switch (category) {
    case APICategory.ats:
      return "ATS";
    case APICategory.hris:
      return "HRIS";
    case APICategory.accounting:
      return "ACCT";
    case APICategory.ticketing:
      return "TCKT";
    case APICategory.crm:
      return "CRM";
    case APICategory.mktg:
      return "MKTG";
    case APICategory.filestorage:
      return "FILE";
  }
};

// Getter for category's publication status. This will be useful for programmatically adding categories, and/or
// updating their beta status
export const publicationStatusForAPICategory = (
  category: APICategory,
): CategoryPublicationStatus => {
  switch (category) {
    case APICategory.ats:
    case APICategory.hris:
    case APICategory.accounting:
    case APICategory.ticketing:
    case APICategory.crm:
    case APICategory.mktg:
    case APICategory.filestorage:
      return CategoryPublicationStatus.active;
    default:
      return CategoryPublicationStatus.inactive;
  }
};

export const footerTitleForAPICategory = (category: APICategory): string => {
  switch (category) {
    case APICategory.ats:
      return "ATS";
    case APICategory.hris:
      return "HR & Payroll";
    case APICategory.crm:
      return "CRM";
    case APICategory.mktg:
      return "MKTG";
    case APICategory.filestorage:
      return "File Storage";
    case APICategory.accounting:
    case APICategory.ticketing:
      return capitalizeFirstLetter(category);
  }
};

export const primaryModelForAPICategory = (category: APICategory): string => {
  switch (category) {
    case APICategory.ats:
      return "candidates";
    case APICategory.hris:
      return "employees";
    case APICategory.crm:
      return "contacts";
    case APICategory.mktg:
      return "automations";
    case APICategory.filestorage:
      return "files";
    case APICategory.accounting:
      return "accounts";
    case APICategory.ticketing:
      return "tickets";
  }
};

export const displayLinksForCategories = (categories: Array<APICategory>): JSX.Element => {
  const displayLinks: React.ReactElement[] = [];
  for (let i = 0; i < categories.length; i += 1) {
    const category = categories[i];
    const displayName = displayNameForAPICategory(category);
    displayLinks.push(<Link to={`/${categoryPagePathForCategory(category)}`}>{displayName}</Link>);
  }
  switch (displayLinks.length) {
    case 0:
      return <></>;
    case 1:
      return displayLinks[0];
    case 2:
      return (
        <>
          {displayLinks[0]} and {displayLinks[1]}
        </>
      );
    default:
      return (
        <>
          {displayLinks.slice(0, -1).join(", ")}, and {displayLinks[displayLinks.length - 1]}
        </>
      );
  }
};

export const titleifyCategory = (category: string): string =>
  category.length > 4
    ? category === "filestorage"
      ? "File Storage"
      : capitalizeFirstLetter(category)
    : category.toUpperCase();

// NOTE: This will serve as "models" of all the display info we need for different categories on our landing page
// In the future, this information will be migrated to models in the database so categories can be added
// and updated through CMS

export const CATEGORY_INFO: Record<APICategory, CategoryInfo> = Object.freeze(
  Object.values(APICategory).reduce(
    (acc, category) => ({
      ...acc,
      [category]: {
        displayName: displayNameForAPICategory(category),
        categoryPreview: categoryPreviewForAPICategory(category),
        fullDisplayName: fullDisplayNameForAPICategory(category),
        featherIcon: featherIconForAPICategory(category),
        pagePath: categoryPagePathForCategory(category),
        publicationStatus: publicationStatusForAPICategory(category),
        footerTitle: footerTitleForAPICategory(category),
        descriptiveName: descriptiveNameForAPICategory(category),
        docsLink: docsLinkForAPICategory(category),
        abbreviation: abbreviationForAPICategory(category),
      },
    }),
    {},
  ) as Record<APICategory, CategoryInfo>,
);

/**
 * Type safely convert a string to an APICategory with a type guard
 */
export const isApiCategoryEnum = (category: string): category is keyof typeof APICategory =>
  Object.keys(APICategory).includes(category);

/**
 * Given a path or tag (eg "available-actions" or "/api/ats/v1/available-actions"), and optional category
 * return the hardcoded additionalEndpointInfo from documentation contant
 */
export const getAdditionalEndpointInfo = (
  endpointPathOrTag: string,
  category?: string,
): AdditionalEndpointInfo | null => {
  const pathSegments = endpointPathOrTag.split("/");
  const tag = pathSegments[pathSegments.length - 1];

  const additionalEndpointInfo = ADDITIONAL_ENDPOINT_INFO_MAP[tag];

  // verify tag is not for a different category (e.g. Teams is deprecated for HRIS but not for Ticketing).
  let isAccurateCategory;
  if (category) {
    isAccurateCategory =
      !additionalEndpointInfo?.category || category === additionalEndpointInfo?.category;
  } else {
    isAccurateCategory =
      !additionalEndpointInfo?.category ||
      endpointPathOrTag.includes(additionalEndpointInfo?.category);
  }

  if (!isAccurateCategory) {
    return null;
  }

  return additionalEndpointInfo;
};

export const validateEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};
