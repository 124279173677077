import React, { MutableRefObject, useLayoutEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

import styled, { css } from "styled-components";
import { sizes } from "../../helpers/style";
import { palette } from "../../styles/theme";

export type Props<ElementType extends HTMLElement> = {
  /**
   * If the callout is currently visible or not.
   * Destroys element on hide. Use state to control
   * this!
   */
  isVisible: boolean;

  /**
   * The element we query for position when the callout
   * shows up. If omitted, the callout will not position
   * properly. Fine for this to be null before the
   * callout is visible.
   */
  positionRef: MutableRefObject<ElementType | null>;
};

interface BubbleProps {
  /**
   * Absolute position to top of target element from top of
   * page. If set, we will use this to position the bubble
   * ABOVE this spot. Takes precedent over $bottom.
   */
  $top: number | null;

  /**
   * Absolute position to center of target element from left
   * of page.
   */
  $center: number;

  /**
   * Absolute position to bottom of target element from top
   * of page. If set, we will use this to position the bubble
   * BELOW this spot. Defers to $top if both set.
   */
  $bottom: number | null;
}

// Arrow dimension in pixels
const arrowSize = 12;

// The shared styles between the bubble and its arrow
const sharedStyles = css`
  position: absolute;
  background: #111317;
  box-shadow: 0px 8px 18px rgba(31, 45, 61, 0.07);
`;

/**
 * Padded bubble, absolutely positioned above a target
 * point. Uses left and top to position itself, then
 * moves it up 100% and left 50% to center it. Has an
 * arrow coming out the middle of the bottom that
 * appears to be part of the container.
 *
 * Use the hook useCallout for easier setup!
 */
const Bubble = styled.div<BubbleProps>`
  ${sharedStyles};
  left: ${({ $center }) => $center}px;
  ${({ $top, $bottom }) => [
    $top &&
      css`
        top: ${$top}px;
      `,
    $bottom &&
      css`
        top: ${$bottom}px;
      `,
    css`
      transform: translateX(-50%) translateY(${$bottom ? 100 : -100}%);
    `,
  ]};

  color: ${palette.white};
  text-align: center;
  display: block;
  padding: 0.75rem 1.25rem;
  border-radius: 6px;
  font-size: ${sizes.toRem(15)};
  line-height: ${sizes.toRem(21)};
  z-index: 999;
  margin-top: -${arrowSize}px;
  margin-bottom: ${arrowSize}px;

  &:before {
    content: "";
    ${sharedStyles};
    width: ${arrowSize}px;
    height: ${arrowSize}px;
    display: block;
    transform-origin: center;
    transform: rotate(-45deg);
    ${({ $top }) => $top && `bottom: -${arrowSize / 2}px`};
    ${({ $bottom }) => $bottom && `top: -${arrowSize / 2}px`};
    left: calc(50% - ${arrowSize / 2}px);
    z-index: -1;
  }
`;

/**
 * Creates a callout bubble with arrow, extending out from the
 * absolute position specified by the element contained in
 * `positionRef`. Control visibility with `isVisible`, and
 * provide any content you want via children - all will appear
 * within the bubble, and the bubble will center above the
 * middle of the target.
 */
function Callout<ElementType extends HTMLElement>({
  children,
  isVisible,
  positionRef,
  className,
}: Props<ElementType> & Pick<React.ComponentProps<"div">, "className" | "children">) {
  // Position variables
  const [top, setTop] = useState<number | null>(null);
  const [center, setCenter] = useState(0);
  const [bottom, setBottom] = useState<number | null>(null);
  const bubbleRef = useRef<HTMLDivElement>(null);

  // Use a scroll listener to update position
  useLayoutEffect(() => {
    let throttleTimeout: boolean | null = null;

    // Sets the offset of the product dropdown to top of screen or bottom of container, whichever is larger
    const updatePosition = () => {
      const rect = positionRef.current?.getBoundingClientRect();
      const bubbleRect = bubbleRef.current?.getBoundingClientRect();
      if (!rect || !bubbleRect || !isVisible) {
        return;
      }
      const threshold = 100;
      const newCenter = rect.x + rect.width / 2 + window.pageXOffset;

      // If the element will be below the upper threshold, set top
      if (rect.y > threshold) {
        setTop(rect.y + window.pageYOffset);
        setBottom(null);
      } else if (rect.bottom < window.outerHeight - threshold) {
        // Otherwise if the element is going to be above the bottom of the window threshold, set bottom
        setTop(null);
        setBottom(rect.bottom + window.pageYOffset);
      }

      // Make sure it stays onscreen width wise
      if (newCenter - bubbleRect.width / 2 < 0) {
        setCenter(bubbleRect.width / 2);
      } else if (newCenter + bubbleRect.width / 2 > window.innerWidth) {
        setCenter(window.innerWidth - bubbleRect.width / 2);
      } else {
        setCenter(newCenter);
      }
    };

    // Throttle with requestAnimationFrame
    const handleScroll = () => {
      if (!throttleTimeout) {
        window.requestAnimationFrame(() => {
          updatePosition();
          throttleTimeout = false;
        });
        throttleTimeout = true;
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isVisible, positionRef]);

  // Position is dependent on scroll position
  const bubble = (
    <Bubble ref={bubbleRef} className={className} $center={center} $top={top} $bottom={bottom}>
      {children}
    </Bubble>
  );
  return isVisible ? ReactDOM.createPortal(bubble, document.body) : null;
}

export default Callout;
