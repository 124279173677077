import React, { useContext } from "react";
import styled from "styled-components";
import { SearchIcon } from "lucide-react";

const MendableContainer = styled.div`
  border-radius: 19px;
  height: 36px;
  transition:
    box-shadow 100ms linear,
    border-color 100ms ease-out;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 0 5px rgba(8, 8, 8, 0.12);
  }
`;

const HotKeyContainer = styled.div`
  border-radius: 23px;
  border: 0.5px solid var(--lm-gray-20, #dce2ea);
  font-size: 14px;
`;

const TextContainer = styled.div`
  white-space: nowrap;
`;

type MendableSearchProps = {
  placeholderText: string;
  showHotKeys: boolean;
  setIsMendableVisible: any;
};
const MendableSearch = ({
  placeholderText,
  showHotKeys,
  setIsMendableVisible,
}: MendableSearchProps) => {
  return (
    <MendableContainer
      className="bg-gray-0 ml-6 pl-2.5 pr-1.5 d-flex align-items-center justify-content-center text-gray-60"
      onClick={() => {
        setIsMendableVisible(true);
      }}
    >
      <div className="d-flex align-items-center">
        <SearchIcon size={14} />
        <TextContainer className="ml-2.5 pr-3 d-flex">{placeholderText}</TextContainer>
        {showHotKeys && <HotKeyContainer className="ml-2.5 pl-1.5 pr-1.5">⌘K</HotKeyContainer>}
      </div>
    </MendableContainer>
  );
};

export default MendableSearch;
