import React, { useMemo } from "react";
import { Accordion } from "react-bootstrap";
import type { RouterLocation } from "types/types";
import { MobileNavToggle } from "../shared-components/MergeToggles";
import {
  AccordionLI,
  AccordionNavLink,
  AccordionUL,
  FeatherIconSpan,
  MobileNavSectionLink,
  NavSectionToggleContainer,
} from "./MobileNavigationBarStyledComponents";
import TertiaryLinkSectionEntry from "./TertiaryLinkSectionEntry";
import { useActiveDocsLink } from "./useActiveDocsLink";
import { PrimaryLinkWithIcon, SecondaryLinkWithIcon } from "./useDocsMobileNavLinks";

type Props = {
  location: RouterLocation;
  mainLink: PrimaryLinkWithIcon | SecondaryLinkWithIcon;
};

/**
 * Typeguard to see which type of link we have by seeing if there are any
 * tertiary sections with items
 */
const isSecondaryLink = (
  link: PrimaryLinkWithIcon | SecondaryLinkWithIcon,
): link is SecondaryLinkWithIcon => {
  const tertiarySections = (link as SecondaryLinkWithIcon)?.tertiarySections;
  return !!tertiarySections && tertiarySections.length > 0;
};

/**
 * Shows one section in the mobile nav - has a list of links, header, and possibly a
 * divider below.
 */
const MobileNavSectionEntryLink = ({ location, mainLink }: Props): React.ReactElement => {
  const {
    isActive,
    secondary: activeSecondaryLink,
    tertiary: activeTertiaryLink,
  } = useActiveDocsLink(location);

  const icon: React.ReactElement = useMemo(
    () =>
      typeof mainLink.icon === "string" ? (
        <FeatherIconSpan className={`fe ${mainLink.icon}`} />
      ) : (
        mainLink.icon
      ),
    [mainLink.icon],
  );

  const isNestedLink = useMemo(() => {
    const sublinks = isSecondaryLink(mainLink)
      ? mainLink.tertiarySections ?? []
      : mainLink.secondaryLinks ?? [];
    return sublinks.length > 0;
  }, [mainLink]);

  const accordionContents = useMemo(() => {
    if (!isNestedLink) {
      return null;
    }

    // If we have secondary links, the contents are tertiary sections
    if (isSecondaryLink(mainLink)) {
      return (
        mainLink.tertiarySections &&
        mainLink.tertiarySections.map((section) => (
          <Accordion
            key={section.header ?? "untitled-header"}
            defaultActiveKey={activeTertiaryLink?.linkTo}
          >
            <TertiaryLinkSectionEntry location={location} {...section} isMobile />
          </Accordion>
        ))
      );
    }

    // Otherwise, we have secondary links as contents
    return (
      mainLink.secondaryLinks &&
      mainLink.secondaryLinks.map((secondaryLink) => (
        <AccordionLI key={secondaryLink.linkTo}>
          <AccordionNavLink
            to={secondaryLink.linkTo}
            $isActive={activeSecondaryLink?.linkTo === secondaryLink.linkTo}
          >
            {secondaryLink.text}
          </AccordionNavLink>
        </AccordionLI>
      ))
    );
  }, [activeSecondaryLink?.linkTo, activeTertiaryLink?.linkTo, isNestedLink, location, mainLink]);

  // Just return a bare link for the non-nested
  if (!isNestedLink) {
    return (
      <li key={mainLink.linkTo}>
        <MobileNavSectionLink to={mainLink.linkTo} $isActive={isActive(mainLink)}>
          <MobileNavToggle eventKey={mainLink.linkTo} isDisabled>
            {icon}
            {mainLink.text}
          </MobileNavToggle>
        </MobileNavSectionLink>
      </li>
    );
  }

  // Otherwise, return a link with toggles and another nested accordion
  return (
    <li key={mainLink.linkTo}>
      <NavSectionToggleContainer>
        <MobileNavToggle eventKey={mainLink.linkTo}>
          {icon} {mainLink.text}
        </MobileNavToggle>
      </NavSectionToggleContainer>
      <Accordion.Collapse eventKey={mainLink.linkTo}>
        <AccordionUL>{accordionContents}</AccordionUL>
      </Accordion.Collapse>
    </li>
  );
};

export default MobileNavSectionEntryLink;
