import { useLocation } from "@reach/router";
import React from "react";
import Helmet from "react-helmet";

const NO_INDEX_URLS = ["/ticketing/projects/"];

interface Props {
  title?: string;
  description?: string;
  imageUrl?: string;
  /**
   * The path to use in the og:url and canonical url.
   */
  urlPath?: string;
}

const DocsHelmet = ({ title, description, imageUrl, urlPath }: Props) => {
  const location = useLocation();
  const rootUrl = "https://docs.merge.dev";
  const docsOpenGraph = "/img/open-graph/merge-docs.jpg";
  const currentPath = urlPath || location.pathname;
  let linkRelAttribute = "canonical";

  if (NO_INDEX_URLS.includes(currentPath)) {
    linkRelAttribute = "canonical noindex";
  }
  return (
    <Helmet>
      <title>{title || "Merge Docs"}</title>
      <meta property="og:title" content={title || "Merge Docs"} />
      <meta
        property="og:description"
        content={description || "Learn how to add Merge to your product."}
      />
      <meta property="og:image" content={imageUrl || `${rootUrl}${docsOpenGraph}`} />
      <meta property="og:url" content={`${rootUrl}${currentPath}`} />
      <meta name="twitter:card" content="summary" />
      <meta property="og:type" content="article" />
      <meta property="og:locale" content="en_US" />
      <link rel={linkRelAttribute} href={`${rootUrl}${currentPath}`} />
    </Helmet>
  );
};

export default DocsHelmet;
