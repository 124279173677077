import React, { Dispatch, SetStateAction, useRef } from "react";
import styled from "styled-components";
import Modal, { ModalRef } from "./Modal";
import { MendableInPlace } from "@mendable/search";
import { MENDABLE_ANON_KEY } from "components/docs/assets/constants";

const mendableStyle = { darkMode: false, accentColor: "#123456" };
const Container = styled.div`
  && {
    button {
      border-radius: 0.5rem !important;
    }
  }
`;
type MendableModalProps = {
  isMendableModalOpen: boolean;
  setIsMendableOpen: Dispatch<SetStateAction<boolean>>;
};

const MendableModal = ({ isMendableModalOpen, setIsMendableOpen }: MendableModalProps) => {
  const modalRef = useRef<ModalRef>(null);
  return (
    <>
      <Modal
        ref={modalRef}
        isVisible={isMendableModalOpen}
        showHeader={false}
        centered={false}
        noPadding
        setIsVisible={setIsMendableOpen}
      >
        <Container>
          <MendableInPlace
            anon_key={MENDABLE_ANON_KEY}
            style={mendableStyle}
            showSimpleSearch
            askAIText="Ask Merge AI"
            hintText="Find or ask anything..."
            messageSettings={{
              openInNewTabForOutsideSources: true,
              legacySourceDisplay: true,
            }}
          />
        </Container>
      </Modal>
    </>
  );
};

export default MendableModal;
