import { useEffect, useRef, useState } from "react";

/**
 * Companion to the Callout.tsx component! Use this to set state
 * for a Callout. Can provide a duration in ms after which to
 * dismiss the callout after it's shown.
 */
export function useCallout<T extends HTMLElement>(visibleTimeoutInMs?: number) {
  const positionRef = useRef<T>(null);
  const [isVisible, setIsVisible] = useState(false);

  // Setup the timeout to reset the visibility if needed
  useEffect(() => {
    if (!visibleTimeoutInMs || !isVisible) {
      return () => {};
    }
    const timeoutId = setTimeout(() => setIsVisible(false), visibleTimeoutInMs);

    return () => clearTimeout(timeoutId);
  }, [isVisible, visibleTimeoutInMs]);

  return { isVisible, positionRef, setIsVisible };
}
