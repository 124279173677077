import React from "react";
import { Accordion } from "react-bootstrap";
import styled, { css } from "styled-components";
import { RouterLocation } from "types/types";
import { getBadgeForEndpoint, HTTPMethodBadge } from "../shared-components/MergeBadges";
import { MobileNavToggle } from "../shared-components/MergeToggles";
import {
  AccordionNavLink,
  MobileNavSectionLink,
  NavSectionToggleContainer,
} from "./MobileNavigationBarStyledComponents";
import TertiaryLinkEntryContent from "./TertiaryLinkEntryContent";
import { useActiveDocsLink } from "./useActiveDocsLink";
import type { TertiaryLink } from "./useDocsNavLinks";

interface Props {
  /**
   * Which link we're showing in this entry
   */
  link: TertiaryLink;

  /**
   * Current page's location
   */
  location: RouterLocation;
}

const Container = styled.div`
  position: relative;
  z-index: 30;
`;

const NavToggle = styled(MobileNavToggle)`
  padding-left: 44px;
`;

const AccordionExpandedContainer = styled.div`
  padding-left: 57px;
`;

const IndentedLink = styled(AccordionNavLink)<{ $isActive?: boolean }>`
  && {
    padding-left: 17px;
    border-left: ${({ $isActive }) => ($isActive ? "2px solid #121314" : "2px solid #ececed")};
    ${({ $isActive }) =>
      $isActive &&
      css`
        margin-left: 0px;
      `};
    background: ${({ $isActive }) => ($isActive ? "#f4f5f6" : "none")};
  }
`;

/**
 * Creates one entry for the mobile nav from a tertiary link
 * with optional quaternaryLinks. Shows active status properly too.
 */
const MobileTertiaryLinkEntry = ({ link, location }: Props): React.ReactElement => {
  const { tertiary: activeTertiaryLink, quaternary: activeQuaternaryLink } =
    useActiveDocsLink(location);
  const { linkTo, text, quaternaryLinks, imageSrc } = link;

  const hasQuaternaryLinks = quaternaryLinks !== undefined && quaternaryLinks.length > 0;
  const quaternaryLinkElements =
    quaternaryLinks &&
    quaternaryLinks.map((quaternaryLink) => (
      <IndentedLink
        $isActive={quaternaryLink.linkTo === activeQuaternaryLink?.linkTo}
        to={quaternaryLink.linkTo}
        title={quaternaryLink.text}
        key={quaternaryLink.linkTo}
      >
        {quaternaryLink.method && <HTTPMethodBadge method={quaternaryLink.method} />}
        {quaternaryLink.text}
      </IndentedLink>
    ));

  // No expanded accordion for the 4th level links
  if (!hasQuaternaryLinks) {
    return (
      <Container key={linkTo}>
        <MobileNavSectionLink to={linkTo} $isActive={linkTo === activeTertiaryLink?.linkTo}>
          <NavToggle eventKey={linkTo} isDisabled={!hasQuaternaryLinks}>
            <TertiaryLinkEntryContent text={text} imageSrc={imageSrc} />
            {getBadgeForEndpoint(linkTo)}
          </NavToggle>
        </MobileNavSectionLink>
      </Container>
    );
  }

  // With links, structure changes to have another toggle level
  return (
    <Container key={linkTo}>
      <NavSectionToggleContainer
        $isActive={linkTo === activeTertiaryLink?.linkTo && !activeQuaternaryLink}
      >
        <NavToggle eventKey={linkTo} isDisabled={!hasQuaternaryLinks}>
          <TertiaryLinkEntryContent text={text} imageSrc={imageSrc} />
          {getBadgeForEndpoint(linkTo)}
        </NavToggle>
      </NavSectionToggleContainer>
      <Accordion.Collapse eventKey={linkTo}>
        <AccordionExpandedContainer>{quaternaryLinkElements}</AccordionExpandedContainer>
      </Accordion.Collapse>
    </Container>
  );
};

export default MobileTertiaryLinkEntry;
