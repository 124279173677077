import React from "react";

import DocsHelmet from "../shared-components/DocsHelmet";

/**
 * A base Helmet for docs, intended to be overridden by children.
 */
const NavHelmet = () => <DocsHelmet />;

export default NavHelmet;
