import styled from "styled-components";

interface LinkButtonInterface {
  color?: string;
  rounded?: boolean;
}

const LinkButton = styled.a<LinkButtonInterface>`
  font-size: 15px;
  line-height: 24px;
  font-weight: 600;
  height: 36px;
  ${(props) => (props.rounded ? `border-radius: 18px !important;` : ``)}
  padding: 5px 16px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  transition:
    box-shadow 100ms linear,
    border-color 100ms ease-out;

  &:hover {
    box-shadow: 0 0 0 5px rgba(8, 8, 8, 0.12);
  }

  ${(props) => handleColor(props)};

  &:disabled {
    opacity: 0.45;
    pointer-events: none;
  }
`;

const handleColor = ({ color }: { color?: string }) => {
  const black = `
    color: #ffffff !important;
    background: #121314 !important;

    &:hover {
      box-shadow: 0px 4px 16px rgba(8, 8, 8, 0.16), 0px 2px 8px rgba(8, 8, 8, 0.14), 0 0 0 5px rgba(8, 8, 8, 0.12);
    }
  `;

  switch (color) {
    case "black":
      return black;
    case "white":
      return `
        color: #121314 !important;
        background: #ffffff !important;
        border: 1px solid #EAEEF3;

        &:hover {
          box-shadow: 0px 4px 16px rgba(8, 8, 8, 0.1), 0px 2px 8px rgba(8, 8, 8, 0.08), 0 0 0 5px rgba(8, 8, 8, 0.04);
        }
      `;
    default:
      return black;
  }
};

export default LinkButton;
