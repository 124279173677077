import { APICategory } from "@merge-api/merge-javascript-shared";
import { graphql, useStaticQuery } from "gatsby";
import { remapAllEndpointMapping } from "helpers/endpointMappings";
import { useMemo } from "react";
import type { EndpointMapping } from "types/types";

export interface QueryType {
  allEndpointMapping: {
    nodes: Array<EndpointMapping>;
  };
}

export type UseEndpointMappingsType = Record<APICategory, Record<string, EndpointMapping>>;

const QUERY = graphql`
  query AllEndpointMappings {
    allEndpointMapping {
      nodes {
        endpoints {
          common_models {
            model_name
            mapped_fields
          }
          path
        }
        integration {
          categories
          color
          image
          name
          abbreviated_name
          slug
          square_image
          webhook_setup_guide_url
          category_beta_status {
            hris
            ats
            accounting
            ticketing
            crm
            mktg
            filestorage
          }
        }
      }
    }
  }
`;

/**
 * Pulls all endpoint mappings and returns them as a map of APICategory to map of integration
 * name -> integration and endpoints.
 */
const useEndpointMappings = (): UseEndpointMappingsType => {
  const { allEndpointMapping } = useStaticQuery<QueryType>(QUERY);
  return useMemo(() => remapAllEndpointMapping(allEndpointMapping), [allEndpointMapping]) as any;
};

export default useEndpointMappings;
