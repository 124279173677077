import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { RouterLocation } from "types/types";
import MobileTertiaryLinkEntry from "./MobileTertiaryLinkEntry";
import TertiaryLinkEntry, { NavLinkActiveIndicatorBackground } from "./TertiaryLinkEntry";
import { useActiveDocsLink } from "./useActiveDocsLink";
import type { TertiaryLinkSection } from "./useDocsNavLinks";

type Props = TertiaryLinkSection & {
  /**
   * Current page location
   */
  location: RouterLocation;

  /**
   * Changes the components we use a bit when it's mobile!
   */
  isMobile?: true;
};

const DEFAULT_ROW_HEIGHT = 36; // px
const EXPANDED_ROW_HEIGHT = 40; // for when there's an image in the link

const NavSectionTitle = styled.div`
  padding: 0 24px;
  margin-top: 26px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #92959e;
`;

const MobileSectionTitle = styled(NavSectionTitle)`
  padding-left: 44px;
  margin-top: 24px;
`;

const MobileActiveIndicatorBackground = styled(NavLinkActiveIndicatorBackground)`
  top: ${({ activeIndex, $rowHeight }) => activeIndex * $rowHeight}px;
`;

/**
 * Element that shows the tertiary and quaternary links for docs navigation. Has a
 * left-aligned sidebar, always visible, except on mobile. Shows tertiary links in sections
 * if the current page's data wants sections, and quaternary links as accordians under the
 * tertiary links as necessary.
 */
const TertiaryLinkSectionEntry = ({
  className,
  location,
  header,
  isMobile,
  tertiaryLinks,
  pathnameRenderRegex,
  hideOnMobile,
}: Props & Pick<React.ComponentProps<"div">, "className">) => {
  const {
    tertiary: activeTertiaryLink,
    quaternary: activeQuaternaryLink,
    currentUrl,
  } = useActiveDocsLink(location);

  if (
    (isMobile && hideOnMobile) ||
    (!isMobile && pathnameRenderRegex && !pathnameRenderRegex.test(currentUrl))
  ) {
    return null;
  }

  // If any of the links have an image, we expand row heights to give a little extra space for the thumbnails
  // eslint-disable-next-line no-nested-ternary
  const rowHeight = tertiaryLinks.some(({ imageSrc }) => !!imageSrc)
    ? EXPANDED_ROW_HEIGHT
    : isMobile
    ? EXPANDED_ROW_HEIGHT
    : DEFAULT_ROW_HEIGHT;

  // Map our links into separate elements for the section
  const linkElements = tertiaryLinks.map((link) =>
    isMobile ? (
      <MobileTertiaryLinkEntry key={link.linkTo} link={link} location={location} />
    ) : (
      <TertiaryLinkEntry key={link.linkTo} link={link} location={location} rowHeight={rowHeight} />
    ),
  );
  const activeTertiaryIndex = tertiaryLinks.findIndex(
    (link) => link.linkTo === activeTertiaryLink?.linkTo,
  );

  // activeTertiaryIndex isnt the right index to use - when these links have sublinks (quaternary links) it doesnt count those
  // and therefore highlights the wrong link - fixing that by adding quaternary links before the current active tertiaryLink
  // to the index
  const actualIndex = tertiaryLinks
    .slice(0, activeTertiaryIndex)
    .reduce(
      (accumulator, link) =>
        accumulator + (link.isAlwaysExpanded ? link.quaternaryLinks?.length ?? 0 : 0),
      activeTertiaryIndex,
    );

  const indicator = isMobile ? (
    <>
      <MobileActiveIndicatorBackground $rowHeight={rowHeight} activeIndex={actualIndex} />
    </>
  ) : (
    <>
      <NavLinkActiveIndicatorBackground $rowHeight={rowHeight} activeIndex={actualIndex} />
    </>
  );

  const title = isMobile ? (
    <MobileSectionTitle key={`nav-section-title-${header}`}>{header}</MobileSectionTitle>
  ) : (
    <NavSectionTitle key={`nav-section-title-${header}`}>{header}</NavSectionTitle>
  );

  return (
    <div className={className}>
      {header && title}
      <div style={{ position: "relative" }}>
        {actualIndex > -1 && !activeQuaternaryLink && indicator}
        {linkElements}
      </div>
    </div>
  );
};

export default TertiaryLinkSectionEntry;
