import { Link } from "gatsby";
import React, { useMemo } from "react";
import { Accordion } from "react-bootstrap";
import styled, { css } from "styled-components";
import { RouterLocation } from "types/types";
import {
  ComingSoonBadge,
  getBadgeForEndpoint,
  HTTPMethodBadge,
} from "../shared-components/MergeBadges";
import { LeftNavToggle } from "../shared-components/MergeToggles";
import TertiaryLinkEntryContent from "./TertiaryLinkEntryContent";
import { useActiveDocsLink } from "./useActiveDocsLink";
import type { TertiaryLink } from "./useDocsNavLinks";

interface Props {
  /**
   * Which link we're showing in this entry
   */
  link: TertiaryLink;

  /**
   * Current page's location
   */
  location: RouterLocation;

  /**
   * How tall the row is in pixels, used for animations
   */
  rowHeight: number;
}

const ACCORDION_PADDING_LEFT = 32; // px

const LeftNavLinkDiv = styled.div`
  width: 100%;
  padding: 0;
`;

const NavSectionLink = styled(Link)<{ $isActive?: boolean; $rowHeight: number }>`
  font-size: 14px;
  line-height: ${({ $rowHeight }) => $rowHeight}px;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: ${({ $isActive }) => ($isActive ? "500" : "400")} !important;
  color: var(--gray90) !important;
  &:hover {
    background: #f4f5f6;
    cursor: pointer;
  }
`;

const AccordionExpandedContainer = styled.div`
  position: relative;
  padding: 0 0 0 ${ACCORDION_PADDING_LEFT}px;
  width: 100%;
`;

const INDICATOR_TRANSITION_TIME = 0.33; // seconds

export const NavLinkActiveIndicatorBar = styled.span<{
  activeIndex: number;
  $rowHeight: number;
}>`
  display: block;
  position: absolute;
  z-index: 50;
  pointer-events: none;
  border-left: 3px solid var(--gray90);
  width: 100%;
  height: ${({ $rowHeight }) => $rowHeight}px;
  top: ${({ activeIndex, $rowHeight }) => activeIndex * $rowHeight}px;
  transition: top ${INDICATOR_TRANSITION_TIME}s;
`;

export const NavLinkActiveIndicatorBackground = styled.span<{
  activeIndex: number;
  $rowHeight: number;
}>`
  display: block;
  position: absolute;
  z-index: 20;
  pointer-events: none;
  background: #f4f5f6;
  padding: 0;
  width: 100%;
  height: ${({ $rowHeight }) => $rowHeight}px;
  top: ${({ activeIndex, $rowHeight }) => activeIndex * $rowHeight}px;
  left: 0;
  transition: top ${INDICATOR_TRANSITION_TIME}s;
`;

const PathLinkActiveIndicatorBar = styled(NavLinkActiveIndicatorBar)<{ activeIndex: number }>`
  border-left: 2px solid var(--gray90);
`;

const PathLinkActiveIndicatorBackground = styled(NavLinkActiveIndicatorBackground)`
  width: calc(100% - ${ACCORDION_PADDING_LEFT}px);
  left: ${ACCORDION_PADDING_LEFT}px;
`;

// Non visible for non-active links means just a transparent color border
const InsetBar = styled.div<{ rowCount: number; $rowHeight: number; $isVisible: boolean }>`
  position: absolute;
  pointer-events: none;
  top: 0px;
  left: ${ACCORDION_PADDING_LEFT}px;
  z-index: 40;
  height: ${({ rowCount, $rowHeight }) => $rowHeight * rowCount}px;
  width: 100%;
  border-left: 2px solid #ececec;
  ${({ $isVisible }) =>
    !$isVisible &&
    css`
      border-color: transparent;
    `}
`;

// These show quaternary links, indented by a bit
const IndentedLink = styled(NavSectionLink)<{ $isActive?: boolean }>`
  display: block;
  position: relative;
  z-index: 30;
  padding: 0 16px 0 16px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    position: relative;
  }

  ${(props) =>
    props.$isActive &&
    css`
      font-weight: 600;
    `}
`;

/**
 * Creates one entry for the left nav or the mobile nav from a tertiary link
 * with optional quaternaryLinks. Shows active status properly too.
 */
const TertiaryLinkEntry = ({ link, location, rowHeight }: Props): React.ReactElement => {
  const { tertiary: activeTertiaryLink, quaternary: activeQuaternaryLink } =
    useActiveDocsLink(location);
  const { linkTo, text, quaternaryLinks, imageSrc, isAlwaysExpanded } = link;

  const hasQuaternaryLinks = quaternaryLinks !== undefined && quaternaryLinks.length > 0;
  const quaternaryLinkElements =
    quaternaryLinks &&
    quaternaryLinks.map((quaternaryLink) => (
      <IndentedLink
        $isActive={quaternaryLink.linkTo === activeQuaternaryLink?.linkTo}
        to={quaternaryLink.linkTo}
        title={quaternaryLink.text}
        key={quaternaryLink.linkTo}
        $rowHeight={rowHeight}
      >
        {quaternaryLink.method && <HTTPMethodBadge method={quaternaryLink.method} />}
        {quaternaryLink.text}
      </IndentedLink>
    ));

  // Creates an element for the quaternary link that's active, if we have one
  const quaternaryLinkDecoration = useMemo(() => {
    if (quaternaryLinks === undefined) {
      return null;
    }
    const activeIndex = quaternaryLinks.findIndex(
      (quaternaryLink) => quaternaryLink.linkTo === activeQuaternaryLink?.linkTo,
    );

    // If we always expand, we hide the borders of the quaternary links that aren't in the active tertiary link's section
    const isInsetBarVisible = isAlwaysExpanded ? link.linkTo === activeTertiaryLink?.linkTo : true;
    if (activeIndex === undefined || activeIndex === -1) {
      return (
        <InsetBar
          rowCount={quaternaryLinks.length}
          $rowHeight={rowHeight}
          $isVisible={isInsetBarVisible}
        />
      );
    }
    return (
      <>
        <PathLinkActiveIndicatorBar activeIndex={activeIndex} $rowHeight={rowHeight} />
        <InsetBar
          rowCount={quaternaryLinks.length}
          $rowHeight={rowHeight}
          $isVisible={isInsetBarVisible}
        />
        <PathLinkActiveIndicatorBackground activeIndex={activeIndex} $rowHeight={rowHeight} />
      </>
    );
  }, [
    activeQuaternaryLink?.linkTo,
    activeTertiaryLink?.linkTo,
    isAlwaysExpanded,
    link.linkTo,
    quaternaryLinks,
    rowHeight,
  ]);

  const accordionContents = (
    <AccordionExpandedContainer>
      {quaternaryLinkDecoration}
      {quaternaryLinkElements}
    </AccordionExpandedContainer>
  );

  // We render a collapsable accordion, unless it's always expanded in which case there's no need.
  const accordion = isAlwaysExpanded ? (
    accordionContents
  ) : (
    <Accordion.Collapse eventKey={linkTo}>{accordionContents}</Accordion.Collapse>
  );

  return (
    <div style={{ position: "relative", zIndex: 30 }} key={linkTo}>
      <LeftNavLinkDiv>
        <NavSectionLink
          to={linkTo}
          $isActive={link.linkTo === activeTertiaryLink?.linkTo}
          $rowHeight={rowHeight}
        >
          <LeftNavToggle
            eventKey={linkTo}
            isDisabled={!hasQuaternaryLinks || isAlwaysExpanded || linkTo === ""}
            rowHeight={rowHeight}
            isTruncatable={isAlwaysExpanded}
          >
            <div className="d-flex align-items-center">
              <div>
                <TertiaryLinkEntryContent text={text} imageSrc={imageSrc} />
              </div>
              {linkTo === "" ? <ComingSoonBadge /> : getBadgeForEndpoint(linkTo)}
            </div>
          </LeftNavToggle>
        </NavSectionLink>
      </LeftNavLinkDiv>
      {accordion}
    </div>
  );
};

export default TertiaryLinkEntry;
