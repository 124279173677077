import React from "react";
import { palette } from "../../../styles/theme";
import { MERGE_PRICING_PAGE, SCOPES_CONFIG_BLOG } from "../navigation/links";
import { APICategory } from "@merge-api/merge-javascript-shared";

/// /////////////////////////////
/// /      Documentation     ////
/// /////////////////////////////

/*
 * What stage in our deprecation process the commonModel/field/other is.
 * Reference: https://www.notion.so/mergeapi/Engineering-API-Deprecation-3aa97cd780524959804feb363c24840a
 */
export enum DeprecationStage {
  // notice is given to customers, marked as deprecated on frontend
  DEPRECATED = "DEPRECATED",

  // removed from docs/dashboard, but still functional via API (and SDKs)
  SUNSET = "SUNSET",

  // fully deleted from codebase, db tables potentially removed.
  RETIRED = "RETIRED",
}

export interface AdditionalEndpointInfo {
  title?: string;
  subtitle?: JSX.Element;
  isBeta?: boolean;
  isComingSoon?: boolean;
  deprecationStage?: DeprecationStage;
  deprecationTooltip?: string;
  headerWarningMessage?: JSX.Element | string;
  pricingMessage?: JSX.Element | string;
  warningMessage?: JSX.Element | string;
  category?: string;
}

export const ADDITIONAL_ENDPOINT_INFO_MAP: Record<string, AdditionalEndpointInfo> = {
  "selective-sync": {
    deprecationStage: DeprecationStage.SUNSET,
  },
  "field-mapping": {
    title: "Field Mapping",
    subtitle: (
      <p className="mb-0">
        Field Mappings are mappings between third-party Remote Fields and user defined Merge fields.
        This includes integration-wide and Linked Account specific Field Mappings. Integration-wide
        Field Mappings apply to all Linked Accounts in your organization for a particular
        integration. Linked Account level Field Mappings are specific to an end user.{" "}
        <a href="https://docs.merge.dev/supplemental-data/field-mappings/overview/" target="_blank">
          Learn more
        </a>
        .
      </p>
    ),
    pricingMessage: (
      <>
        Programmatically configuring Field Mappings is only available on our Enterprise plans. View
        the{" "}
        <a href="https://www.merge.dev/pricing" target="_blank">
          Merge Plans
        </a>{" "}
        to learn more.
      </>
    ),
  },
  scopes: {
    isBeta: true,
    title: "Scopes",
    subtitle: (
      <p className="mb-0">
        <p>
          Scopes allows for precise control over data permissions, ensuring that only relevant
          Common Models and fields are synced through Merge. Disabling a Common Model Scope will
          also disable all of its associated fields.
        </p>
        <p>Below, you’ll find endpoints related to two levels of Scopes. </p>
        <ul>
          <li>
            <b>Default Scopes</b> apply to new Linked Accounts for a given category
          </li>
          <li>
            <b>Linked Account Scopes</b> apply only to a specific end user (Linked Account).
          </li>
        </ul>
        The Scopes applied to a Linked Account are first determined by the Linked Account Scopes,
        and fall back to Default Scopes for any that haven't been set.
        <a
          href="https://help.merge.dev/en/articles/5950052-common-model-and-field-scopes"
          target="_blank"
        >
          {" "}
          Learn More
        </a>
        .
      </p>
    ),
    pricingMessage: (
      <>
        This feature is only available in beta to select customers on our Enterprise plan.{" "}
        <a href="mailto: support@merge.dev">Contact us</a> for more information.
      </>
    ),
  },
  "timesheet-entries": {
    isBeta: true,
    category: APICategory.hris,
  },
  "bank-feed-accounts": {
    isBeta: true,
    category: APICategory.accounting,
  },
  "bank-feed-transactions": {
    isBeta: true,
    category: APICategory.accounting,
  },
  "accounting-periods": {
    isBeta: true,
    category: APICategory.accounting,
  },
  "job-postings": {
    isBeta: true,
    category: "ats",
  },
  "default-scopes": {
    isBeta: true,
  },
  "linked-account-scopes": {
    isBeta: true,
  },
  roles: {
    isBeta: true,
    category: APICategory.ticketing,
  },
  projects: {
    category: APICategory.ticketing,
    deprecationStage: DeprecationStage.SUNSET,
    deprecationTooltip: "Deprecated in favor of the Collection object.",
    headerWarningMessage: (
      <>
        Merge will no longer support the Project common model after April 30th, 2023. This means we
        will not add any improvements or support any bug fixes related to Projects. We strongly
        recommend using Collections as it has the same capabilities as Projects and is more
        comprehensive. If you have any questions, please contact us.
      </>
    ),
  },
  "remote-fields": {
    isBeta: true,
  },
  employees: {
    isBeta: true,
    category: APICategory.accounting,
  },
  "async-tasks": {
    isBeta: true,
    category: APICategory.accounting,
  },
  "field-mappings": {
    isBeta: true,
  },
  "{field_mapping_id}": {
    isBeta: true,
  },
  configurations: {
    isBeta: true,
    pricingMessage: (
      <>
        This feature is only available in beta to select customers on our Enterprise plan.{" "}
        <a href="mailto: support@merge.dev">Contact us</a> for more information.
      </>
    ),
  },
  meta: {
    isBeta: true,
    pricingMessage: (
      <>
        This feature is only available in beta to select customers on our Enterprise plan.{" "}
        <a href="mailto: support@merge.dev">Contact us</a> for more information.
      </>
    ),
  },
  "common-model-scopes": {
    pricingMessage: (
      <>
        This feature is only available to customers on our Professional or Enterprise plans. See our{" "}
        <a href={MERGE_PRICING_PAGE} target="_blank" rel="noreferrer">
          pricing page
        </a>{" "}
        for more information.
      </>
    ),
    warningMessage: (
      <>
        Scope configurations set here will not apply to passthrough requests. Learn more about our
        scopes configuration&nbsp;
        <a href={SCOPES_CONFIG_BLOG} target="_blank" rel="noreferrer">
          here
        </a>
        .
      </>
    ),
  },
  "audit-trail": {
    pricingMessage: (
      <>
        This feature is only available to customers on our Enterprise plan. See our{" "}
        <a href={MERGE_PRICING_PAGE} target="_blank" rel="noreferrer">
          pricing page
        </a>{" "}
        for more information.
      </>
    ),
  },
  teams: {
    category: APICategory.hris,
    deprecationStage: DeprecationStage.SUNSET,
    deprecationTooltip: "Deprecated in favor of the Group object.",
    headerWarningMessage: (
      <>
        Merge will no longer support the <code>Team</code> common model after{" "}
        <b>April 20th, 2023</b>. This means we will not add any improvements or support any bug
        fixes related to Teams. We strongly recommend using the <code>Group</code> common model as
        it has the same capabilities as Teams and is more comprehensive. If you have any questions,
        please contact us.
      </>
    ),
  },
};

interface AdditionalCommonModelInfo {
  [key: string]: {
    [key: string]: {
      deprecationStage?: DeprecationStage;
      deprecationMessage?: React.ReactNode;
      fields?: {
        [key: string]: {
          deprecationStage?: DeprecationStage;
          deprecationMessage?: React.ReactNode;
        };
      };
    };
  };
}

export const ADDITIONAL_COMMON_MODEL_INFO: AdditionalCommonModelInfo = {
  [APICategory.hris]: {
    Employee: {
      fields: {
        team: {
          deprecationStage: DeprecationStage.SUNSET,
        },
        custom_fields: {
          deprecationStage: DeprecationStage.SUNSET,
        },
        hire_date: {
          deprecationStage: DeprecationStage.DEPRECATED,
        },
      },
    },
    Team: {
      deprecationStage: DeprecationStage.SUNSET,
      deprecationMessage: (
        <>
          Merge has deprecated this model and will no longer support it via API after{" "}
          <b>February 1, 2024</b>. We strongly recommend using the <code>Group</code> common model
          as it has the same capabilities as <code>Teams</code> and is more comprehensive.
        </>
      ),
    },
    Benefit: {
      fields: {
        benefit_plan_type: {
          deprecationStage: DeprecationStage.DEPRECATED,
          deprecationMessage: (
            <>
              Merge will no longer support this field after <b>8/1/2023</b>. We strongly recommend
              using the <code>Employer Benefit</code> Common Model to access this field.
            </>
          ),
        },
      },
    },
  },
  [APICategory.ticketing]: {
    Project: {
      deprecationStage: DeprecationStage.DEPRECATED,
      deprecationMessage: (
        <>
          Merge has deprecated this model and will no longer support it via API after{" "}
          <b>April 30th, 2023</b>. We strongly recommend using the <code>Collection</code> common
          model as it has the same capabilities as <code>Projects</code> and is more comprehensive.
        </>
      ),
    },
  },
  [APICategory.accounting]: {
    CreditNoteLineItem: {
      fields: {
        tracking_category: {
          deprecationStage: DeprecationStage.SUNSET,
        },
      },
    },
    VendorCreditLine: {
      fields: {
        tracking_category: {
          deprecationStage: DeprecationStage.SUNSET,
        },
      },
    },
    PurchaseOrderLineItem: {
      fields: {
        tracking_category: {
          deprecationStage: DeprecationStage.SUNSET,
        },
      },
    },
    JournalLine: {
      fields: {
        tracking_category: {
          deprecationStage: DeprecationStage.SUNSET,
        },
      },
    },
    InvoiceLineItem: {
      fields: {
        tracking_category: {
          deprecationStage: DeprecationStage.SUNSET,
        },
      },
    },
    ExpenseLine: {
      fields: {
        tracking_category: {
          deprecationStage: DeprecationStage.SUNSET,
        },
      },
    },
    Account: {
      fields: {
        type: {
          deprecationStage: DeprecationStage.DEPRECATED,
        },
      },
    },
    AccountingTransactionLineItem: {
      fields: {
        tracking_category: {
          deprecationStage: DeprecationStage.SUNSET,
        },
      },
    },
  },
  [APICategory.ats]: {},
  [APICategory.crm]: {},
  [APICategory.filestorage]: {},
  [APICategory.mktg]: {},
};

/// /////////////////////////////
/// /         Time           ////
/// /////////////////////////////
export const SEC_IN_YEAR = 60 * 60 * 24 * 365;

/**
 * Breakpoints, in px, for responsive width media queries.
 * Lifted from Bootstrap https://getbootstrap.com/docs/5.0/layout/breakpoints
 */
export const BREAKPOINTS = {
  XS: 0,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1400,
  XXXL: 1600,
};

export const COLORS = {
  BLACK: palette.black,
  DARK_GRAY: palette.slate,
  LIGHT_GRAY: palette.gray,
};

export const MENDABLE_ANON_KEY = "d05418d3-29b3-4942-b03b-51f05446823e";

export const INTEGRATION_SLUGS_WITHOUT_SYNC_FREQUENCY_OR_ENDPOINTS = ["quickbooks-desktop"];
