import { HashProvider } from "components/docs/shared-components/HashContext";
import PreloadedFont from "components/generic/PreloadedFont";
import React from "react";
import { Helmet } from "react-helmet";
import GlobalStyle from "styles/globalStyle";
import { RouterLocation } from "../../../types/types";
import Navigation from "../navigation/Navigation";

interface Props {
  children: JSX.Element;
  location: RouterLocation;
}

const DocsLayout = ({ children, location }: Props) => (
  <div className="merge-docs">
    <GlobalStyle />
    <PreloadedFont fontSet="inter" />
    <Helmet>
      <title>Merge Docs</title>
    </Helmet>
    <HashProvider>
      <Navigation location={location}>
        <div className="main-content pb-9">
          <div className="container-fluid" style={{ maxWidth: "1600px" }}>
            {children}
          </div>
        </div>
      </Navigation>
    </HashProvider>
  </div>
);

export default DocsLayout;
