import styled from "styled-components";

interface Props {
  /**
   * Are we stacking vertically? Defaults to false
   */
  $isVertical?: boolean;

  /**
   * If specified, the column or row gap
   */
  $gap?: number;
}

/**
 * Creates a stack component using flexbox.
 */
export const Stack = styled.div<Props>`
  display: flex;
  flex-direction: ${({ $isVertical }) => ($isVertical ? "column" : "row")};
  ${({ $gap }) => $gap !== undefined && `gap: ${$gap}px`};
`;

/**
 * Stacks content and aligns items in the center
 */
export const CenteredStack = styled(Stack)`
  && {
    align-items: center;
  }
`;

/**
 * Stacks content and aligns items at the start
 */
export const StartStack = styled(Stack)`
  && {
    align-items: start;
  }
`;

/**
 * Stacks content and aligns items at the baseline
 */
export const BaselineStack = styled(Stack)`
  && {
    align-items: baseline;
  }
`;

/**
 * Stacks content and justifies contents in the center
 */
export const SpacedStack = styled(Stack)`
  && {
    justify-content: space-between;
  }
`;
