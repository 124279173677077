/**
 * Merge Color Spectrum - SOURCE OF TRUTH FOR HEX CODES
 * Full spectrum of on-brand colors
 */
export const spectrum = {
  gray0: "#f2f4f7",
  gray10: "#eaeef3",
  gray20: "#dce2ea",
  gray30: "#c9d0da",
  gray40: "#b1b8c3",
  gray50: "#939aa5",
  gray60: "#737882",
  gray70: "#50545b",
  gray80: "#2e3135",
  gray90: "#121314",

  slate0: "#f5f8ff",
  slate10: "#E7EEFC",
  slate20: "#D4DEF2",
  slate30: "#BDC9E1",
  slate40: "#A3B0CA",
  slate50: "#8793AB",
  slate60: "#697387",
  slate70: "#495160",
  slate80: "#2B2F39",
  slate90: "#111317",

  red0: "#fff2f3",
  red10: "#fdbfc4",
  red20: "#fb8d96",
  red30: "#f7636f",
  red40: "#f14150",
  red50: "#e62837",
  red60: "#d61525",
  red70: "#be0a19",
  red80: "#a00310",
  red90: "#7d000a",

  orange0: "#FFF7ED",
  orange10: "#FFEDD5",
  orange20: "#FED7AA",
  orange30: "#FDBA74",
  orange40: "#FB923C",
  orange50: "#F97316",
  orange60: "#EA580C",
  orange70: "#C2410C",
  orange80: "#9A3412",
  orange90: "#7C2D12",

  amber0: "#FFFBEB",
  amber10: "#FEF3C7",
  amber20: "#FDE68A",
  amber30: "#FCD34D",
  amber40: "#FBBF24",
  amber50: "#F59E0B",
  amber60: "#D97706",
  amber70: "#B45309",
  amber80: "#92400E",
  amber90: "#78350F",

  yellow0: "#fff9e6",
  yellow10: "#fbe8ab",
  yellow20: "#f6d973",
  yellow30: "#efcb43",
  yellow40: "#e6bb1d",
  yellow50: "#d9a800",
  yellow60: "#ca8f00",
  yellow70: "#b37200",
  yellow80: "#955400",
  yellow90: "#733900",

  green0: "#F0FDF4",
  green10: "#DCFCE7",
  green20: "#BBF7D0",
  green30: "#86EFAC",
  green40: "#4ADE80",
  green50: "#22C55E",
  green60: "#16A34A",
  green70: "#15803D",
  green80: "#166534",
  green90: "#14532D",

  emerald0: "#ECFDF5",
  emerald10: "#D1FAE5",
  emerald20: "#A7F3D0",
  emerald30: "#6EE7B7",
  emerald40: "#34D399",
  emerald50: "#10B981",
  emerald60: "#059669",
  emerald70: "#047857",
  emerald80: "#065F46",
  emerald90: "#064E3B",

  teal0: "#ebfcfa",
  teal10: "#ace9e0",
  teal20: "#76d5c7",
  teal30: "#4cc2b0",
  teal40: "#2eae9b",
  teal50: "#1a9b87",
  teal60: "#0d8775",
  teal70: "#067463",
  teal80: "#026052",
  teal90: "#004d41",

  cyan0: "#ECFEFF",
  cyan10: "#CFFAFE",
  cyan20: "#A5F3FC",
  cyan30: "#67E8F9",
  cyan40: "#22D3EE",
  cyan50: "#06B6D4",
  cyan60: "#0891B2",
  cyan70: "#0E7490",
  cyan80: "#155E75",
  cyan90: "#164E63",

  sky0: "#F0F9FF",
  sky10: "#E0F2FE",
  sky20: "#BAE6FD",
  sky30: "#7DD3FC",
  sky40: "#38BDF8",
  sky50: "#0EA5E9",
  sky60: "#0284C7",
  sky70: "#0369A1",
  sky80: "#075985",
  sky90: "#0C4A6E",

  blue0: "#F2F5FF",
  blue10: "#B1C7FF",
  blue20: "#719DFF",
  blue30: "#377AFD",
  blue40: "#075FF7",
  blue50: "#0042E5",
  blue60: "#002CCE",
  blue70: "#001BB4",
  blue80: "#000D99",
  blue90: "#000480",

  indigo0: "#f2f4ff",
  indigo10: "#bec5fc",
  indigo20: "#8b91f7",
  indigo30: "#6060f0",
  indigo40: "#473ee5",
  indigo50: "#3625d6",
  indigo60: "#2b14c3",
  indigo70: "#2209ad",
  indigo80: "#1b0395",
  indigo90: "#150080",

  purple0: "#f6f2ff",
  purple10: "#d3befc",
  purple20: "#b28bf7",
  purple30: "#9760f0",
  purple40: "#813ee5",
  purple50: "#6e25d6",
  purple60: "#5e14c3",
  purple70: "#4f09ad",
  purple80: "#420395",
  purple90: "#370080",

  pink0: "#fff2f8",
  pink10: "#fcbedb",
  pink20: "#f78bbe",
  pink30: "#f060a3",
  pink40: "#e53e8c",
  pink50: "#d62578",
  pink60: "#c31466",
  pink70: "#ad0955",
  pink80: "#950347",
  pink90: "#80003c",
};

/**
 * Merge Color Palette
 * Palette of primary used colors
 *
 * Palette should be first resort when picking colors
 * If a color isn't available in Palette, move a color from Spectrum to Palette before using
 */
export const palette = {
  white: "#ffffff",

  lightGray: spectrum.gray0,
  border: spectrum.gray10,
  empty: spectrum.gray30,
  placeholder: spectrum.gray40,
  gray: spectrum.gray50,
  slate: spectrum.gray60,
  graphite: spectrum.gray70,
  black: spectrum.slate90,

  red: spectrum.red50,
  redHover: spectrum.red70,

  green: spectrum.teal50,
  greenHover: spectrum.teal70,

  blue: spectrum.blue40,
  blueHover: spectrum.blue60,

  yellow: spectrum.yellow50,
  yellowHover: spectrum.yellow70,

  indigo: spectrum.indigo40,
  indigoHover: spectrum.indigo60,

  purple: spectrum.purple50,
  purpleHover: spectrum.purple70,

  pink: spectrum.pink50,
  pinkHover: spectrum.pink70,

  /**
   * Custom colors... might need to conform in future
   */
  royalBlue: "#0061c2",
  darkNavy: "#050520",
  lightIndigo: "#5A5AFA",
  darkIndigo: "#030328",
  darkBlue: "#0d2756",
  paleGreen: "#1cccb1",
  neonTeal: "#63efff",
};
