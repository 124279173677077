import React, { Fragment } from "react";
import type { RouterLocation } from "types/types";
import { NavSectionTitle } from "./MobileNavigationBarStyledComponents";
import MobileNavSectionEntryLink from "./MobileNavSectionEntryLink";
import type { MobileNavSection } from "./useDocsMobileNavLinks";

type Props = MobileNavSection & {
  location: RouterLocation;
};

/**
 * Shows one section in the mobile nav - has a list of links, header, and possibly a
 * divider below.
 */
const MobileNavSectionEntry = ({ location, header, primaryLinks }: Props): React.ReactElement => (
  // There's only one section without a header so this works
  <Fragment key={header ?? "untitled-header"}>
    {header && <NavSectionTitle>{header}</NavSectionTitle>}
    {primaryLinks.map((primaryLink) => (
      <MobileNavSectionEntryLink
        key={primaryLink.linkTo}
        mainLink={primaryLink}
        location={location}
      />
    ))}
  </Fragment>
);

export default MobileNavSectionEntry;
