import React from "react";

import LogoDesktop from "../../../assets/svg/logos/merge/logo-docs-v2.svg";
import LogoMobile from "../../../assets/svg/logos/merge/logo-docs-v2-mobile.svg";

export const MergeDocsLogoDesktop = () => <LogoDesktop />;

/**
 * Sharing an svg between React components and then marking one of them as having "display: none" in css
 * causes the other one to not show up completely (in this case, the linear gradient was disappearing).
 * To workaround this, here is a mobile logo that uses a different svg file with a different linear gradient id
 * from the desktop version.
 *
 * More info:
 * https://stackoverflow.com/questions/45603664/svg-not-rendering-correctly-when-used-with-by-multiple-react-components
 */
export const MergeDocsLogoMobile = () => <LogoMobile />;
