import React, { useState } from "react";
import { ChevronDown, ArrowUpRight } from "lucide-react";

type NavigationDropDownItem = {
  url: string;
  text: string;
};
type NavigationDropDownProps = {
  navigationDropDownItems: NavigationDropDownItem[];
};
const NavigationDropDown = ({ navigationDropDownItems }: NavigationDropDownProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <button
      onClick={() => {
        setIsExpanded(!isExpanded);
      }}
      className="pl-2.5 pr-2.5 ml-4 bg-transparent hover:text-[#727581] border-0"
      onMouseEnter={() => {
        setIsExpanded(true);
      }}
      onMouseLeave={() => {
        setIsExpanded(false);
      }}
    >
      <div className="flex items-center bg-transparent">
        <div className="text-[15px] font-semibold">Resources</div>
        <ChevronDown size={16} className={`ml-1 ${isExpanded ? "rotate-180" : ""}`} />
      </div>
      {isExpanded && (
        <div className="absolute z-10 bg-white pt-2 pb-2 shadow-md rounded-[6px] w-[135px]">
          {navigationDropDownItems.map(({ text, url }) => {
            return (
              <a
                href={url}
                target="_blank"
                className="flex items-center cursor-pointer text-black text-[15px] font-semibold pl-3 pt-1.5 pb-1.5 hover:bg-gray-0"
              >
                <div>{text}</div>
                <div className="ml-1">
                  <ArrowUpRight size={16} />
                </div>
              </a>
            );
          })}
        </div>
      )}
    </button>
  );
};
export default NavigationDropDown;
