import { useEffect, useLayoutEffect, useRef, useState } from "react";

interface UseModalProps {
  /**
   * The class to apply to elements when the modal is visible
   */
  visibleClass: string;

  /**
   * The length of the animation in or out of the modal
   */
  animationDurationInMs: number;
}

/**
 * This hook should be paired with Modal.tsx to set state for the modal and allow for animating the
 * component in and out. Importantly, both the refs it returns must be set on the Modal for the
 * animations to work properly.
 * @returns An object for querying and setting visibility with animation, plus the refs for the overlay
 * element and the modal itself (the content). Both refs must be specified in Modal.tsx for it to work.
 */
export const useModal = ({ visibleClass, animationDurationInMs }: UseModalProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);

  // Naming is hard - this controls overall visibility for when the animation finishes.
  // It can be used as source of truth for if the full modal is visible from a parent.
  const [isActuallyVisible, setIsActuallyVisible] = useState(false);

  // This controls whether or not we're animating to visible or invisible. It gets set
  // to true before the CSS animation starts (and it triggers the animation to start).
  const [isAnimatingVisibility, setIsAnimatingVisibility] = useState(false);

  // Changes classes immediately, then changes the actual visibility after the animation finishes
  const setVisibleWithAnimation = (isAnimating: boolean) => {
    setIsAnimatingVisibility(isAnimating);
    const timeoutId = setTimeout(() => setIsActuallyVisible(isAnimating), animationDurationInMs);
    return () => {
      clearTimeout(timeoutId);
    };
  };

  // If the parent's view of is visible changes, animate that change locally
  useEffect(() => {
    setIsAnimatingVisibility(isActuallyVisible);
  }, [isActuallyVisible]);

  /**
   * Via next event loop, listens to isAnimatingVisibility to modify the
   * ref classes on the next event loop to trigger CSS animations. If not wrapped
   * in setTimeout, changes classes as soon as modal shows up, and doesn't
   * animate as a result.
   */
  useLayoutEffect(() => {
    // requestAnimationFrame doesn't work because Firefox is smart
    // enough to run it on first layout & not animate as a result
    setTimeout(() => {
      if (!contentRef.current || !backgroundRef.current) {
        return;
      }

      // Adds or removes the visible class via fun string manipulation
      const setClass = (currentClass: string) => {
        if (isAnimatingVisibility) {
          return `${currentClass} ${visibleClass}`;
        }
        return currentClass.replace(visibleClass, "");
      };

      // Both refs need updating
      contentRef.current.className = setClass(contentRef.current.className);
      backgroundRef.current.className = setClass(backgroundRef.current.className);
    }, 0);
  }, [isAnimatingVisibility, visibleClass]);

  // The parent needs to use the refs and modify visibility
  return {
    isActuallyVisible,
    setVisibleWithAnimation,
    contentRef,
    backgroundRef,
  };
};
