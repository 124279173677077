/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { OpenAPIV3 } from "openapi-types";

type OpenAPIBaseSchemaObject = OpenAPIV3.BaseSchemaObject;

export function normalizeArray(arr: any) {
  if (Array.isArray(arr)) return arr;
  return [arr];
}

const primitives = {
  string: () => "string",
  string_email: () => "user@example.com",
  "string_date-time": () => new Date().toISOString(),
  string_date: () => new Date().toISOString().substring(0, 10),
  string_uuid: () => "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  string_hostname: () => "example.com",
  string_binary: () => "bytes",
  string_ipv4: () => "198.51.100.42",
  string_ipv6: () => "2001:0db8:5b96:0000:0000:426f:8e17:642a",
  number: () => 0,
  number_float: () => 0.0,
  integer: () => 0,
  boolean: (schema: any) => (typeof schema.default === "boolean" ? schema.default : true),
};

export function isObject(obj: any) {
  return !!obj && typeof obj === "object";
}

export function objectify(thing: any) {
  if (!isObject(thing)) return {};
  return thing;
}

export function isFunc(thing: any) {
  return typeof thing === "function";
}

const primitive = (schema: any) => {
  schema = objectify(schema);
  const { type, format } = schema;

  // @ts-ignore
  const fn = primitives[`${type}_${format}`] || primitives[type];

  if (isFunc(fn)) return fn(schema);

  return `Unknown Type: ${schema.type}`;
};

export function deeplyStripKey(input: any, keyToStrip: any, predicate = () => true) {
  if (typeof input !== "object" || Array.isArray(input) || input === null || !keyToStrip) {
    return input;
  }

  const obj = { ...input };

  Object.keys(obj).forEach((k) => {
    // @ts-ignore
    if (k === keyToStrip && predicate(obj[k], k)) {
      delete obj[k];
      return;
    }
    obj[k] = deeplyStripKey(obj[k], keyToStrip, predicate);
  });

  return obj;
}

// do a couple of quick sanity tests to ensure the value
// looks like a $$ref that swagger-client generates.
const sanitizeRef = (value: any) =>
  deeplyStripKey(
    value,
    "$$ref", // @ts-ignore
    (val) => typeof val === "string" && val.indexOf("#") > -1,
  );

const liftSampleHelper = (oldSchema: any, target: any) => {
  if (target.example === undefined && oldSchema.example !== undefined) {
    target.example = oldSchema.example;
  }
  if (target.default === undefined && oldSchema.default !== undefined) {
    target.default = oldSchema.default;
  }
  if (target.enum === undefined && oldSchema.enum !== undefined) {
    target.enum = oldSchema.enum;
  }
  if (target.xml === undefined && oldSchema.xml !== undefined) {
    target.xml = oldSchema.xml;
  }
  return target;
};

export const sampleFromSchemaGeneric = (schema: OpenAPIBaseSchemaObject): any => {
  // @ts-ignore
  const { example, properties, additionalProperties, items } = schema;
  // @ts-ignore
  let { type } = schema;

  const includeReadOnly = true;
  const res = {};

  const usePlainValue = example !== undefined || (schema && schema.default !== undefined);

  const hasOneOf = !usePlainValue && schema && schema.oneOf && schema.oneOf.length > 0;
  const hasAnyOf = !usePlainValue && schema && schema.anyOf && schema.anyOf.length > 0;
  if (!usePlainValue && (hasOneOf || hasAnyOf)) {
    const someSchema = hasOneOf
      ? // @ts-ignore
        schema.oneOf[0]
      : // @ts-ignore
        schema.anyOf[0];
    liftSampleHelper(schema, someSchema);
    // @ts-ignore
    return sampleFromSchemaGeneric(someSchema);
  }

  // try recover missing type
  if (schema && !type) {
    if (properties || additionalProperties) {
      type = "object";
    } else if (items) {
      type = "array";
    } else if (!usePlainValue) {
      return;
    }
  }

  // add to result helper init for xml or json
  const props = objectify(properties);

  const addPropertyToResult = (propName: any) =>
    // @ts-ignore
    (res[propName] = sampleFromSchemaGeneric(props[propName]));

  // check for plain value and if found use it to generate sample from it
  if (usePlainValue) {
    let sample;
    if (example !== undefined) {
      sample = sanitizeRef(example);
    } else {
      sample = sanitizeRef(schema.default);
    }

    return sample;
  }

  // use schema to generate sample

  if (type === "object") {
    for (const propName in props) {
      // eslint-disable-next-line no-prototype-builtins
      if (!props.hasOwnProperty(propName)) {
        continue;
      }
      if (props[propName] && props[propName].deprecated) {
        continue;
      }
      if (props[propName] && props[propName].readOnly && !includeReadOnly) {
        continue;
      }
      if (props[propName] && props[propName].writeOnly) {
        continue;
      }
      addPropertyToResult(propName);
    }

    if (additionalProperties === true) {
      // @ts-ignore
      res.additionalProp1 = {};
    } else if (additionalProperties) {
      const additionalProps = objectify(additionalProperties);
      const additionalPropSample = sampleFromSchemaGeneric(additionalProps);

      for (let i = 1; i < 4; i++) {
        // @ts-ignore
        res[`additionalProp${i}`] = additionalPropSample;
      }
    }

    return res;
  }

  if (type === "array") {
    let sampleArray;
    if (Array.isArray(items.anyOf)) {
      // @ts-ignore

      sampleArray = items.anyOf.map((i) => sampleFromSchemaGeneric(liftSampleHelper(items, i)));
    } else if (Array.isArray(items.oneOf)) {
      // @ts-ignore

      sampleArray = items.oneOf.map((i) => sampleFromSchemaGeneric(liftSampleHelper(items, i)));
    } else {
      sampleArray = [sampleFromSchemaGeneric(items)];
    }

    return sampleArray;
  }

  let value;
  if (schema && Array.isArray(schema.enum)) {
    // display enum first value
    value = normalizeArray(schema.enum)[0];
  } else if (schema) {
    // display schema default
    value = primitive(schema);
  } else {
    return;
  }
  if (type === "file") {
    return;
  }

  return value;
};

export const inferSchema = (thing: any) => {
  if (thing.schema) thing = thing.schema;

  if (thing.properties) {
    thing.type = "object";
  }

  return thing; // Hopefully this will have something schema like in it... `type` for example
};

export const getSampleSchema = (schema: OpenAPIBaseSchemaObject): string => {
  const res = sampleFromSchemaGeneric(schema);

  return JSON.stringify(res, null, 2);
};

export const toTitleCase = (str: string) =>
  str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());

/*
 * Takes a docs slug in the format "category/common-model-docs-slug/" and returns the common model title
 */

export function transformDocsSlugToTitle(docsSlug: string) {
  const modelName = docsSlug.split("/")[1];
  const transformedName = modelName
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return transformedName;
}

export const openInNewTab = (url: string) => {
  window.open(url, "_blank");
};
