import { useEffect, useState } from "react";

const useCommandKPressed = () => {
  const [commandKPressed, setCommandKPressed] = useState(false);

  const handleKeyDown = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === "k") {
      setCommandKPressed(true);
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === "Meta" || event.ctrlKey) {
      setCommandKPressed(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return commandKPressed;
};

export default useCommandKPressed;
