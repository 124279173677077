import Callout from "components/generic/Callout";
import { useCallout } from "hooks/useCallout";
import React, { useRef } from "react";
import styled from "styled-components";

/**
 * Type for an element that also handles mouse movement
 */
type HoverableElement = HTMLElement & {
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  ref?: React.RefObject<HoverableElement>;
};

interface Props {
  /**
   * This is where the tooltip emanates from when this element is hovered
   */
  children: React.ReactElement<HoverableElement>;

  /**
   * The actual content of the tooltip
   */
  content: React.ReactElement | string;

  /**
   * Whether tooltip should allow for 100% width
   */
  fullWidth?: boolean;

  /** Seconds to delay closing on leave */
  delay?: number;
}

// Otherwise we run the risk of going off the screen
const MaxWidthCallout = styled(Callout)<{ $fullWidth?: boolean }>`
  ${({ $fullWidth }) => ($fullWidth ? `max-width: 100%;` : `max-width: 24em;`)}
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  background: #111317;
`;

/**
 * Shows a dark tooltip with given content that shows when hovering the parent element.
 * Uses Callout under the hood with the same styling - this just encapsulates some of the
 * display logic. Contents of the tooltip are just whatever children we pass in.
 */
const Tooltip = ({ children, content, fullWidth, delay = 0 }: Props) => {
  const { isVisible, positionRef, setIsVisible } = useCallout<HoverableElement>();

  // this is somewhat hacky way of handling deleyed closing. will improve when tooltip is moved into design system
  const shouldClose = useRef(true);

  const triggerWithHoverState = React.cloneElement(children, {
    onMouseEnter: () => {
      shouldClose.current = false;
      setIsVisible(true);
    },
    onMouseLeave: () => {
      shouldClose.current = true;

      setTimeout(() => {
        if (shouldClose.current) setIsVisible(false);
      }, delay);
    },
    ref: positionRef,
  });

  return (
    <>
      {triggerWithHoverState}
      <MaxWidthCallout isVisible={isVisible} positionRef={positionRef} $fullWidth={fullWidth}>
        {content}
      </MaxWidthCallout>
    </>
  );
};

export default Tooltip;
