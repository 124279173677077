/* eslint-disable react/jsx-filename-extension, import/prefer-default-export */
import React from "react";
import DocsLayout from "./src/components/docs/layout/DocsLayout";
import "./src/styles/scss/main.scss";
import "./src/styles/tailwind/tailwind.css";

/**
 * Wraps all /docs pages in a DocsLayout except the 404 page, as that
 * page handles its own layout to keep SSR vs client side hydration
 * consistent.
 * @type {import("gatsby").GatsbyBrowser['wrapPageElement']}
 * @returns {React.ReactElement};
 */
export const wrapPageElement = ({ element, props }) => {
  const is404Page = props.pageResources?.page.path.includes("404");
  if (!is404Page) {
    return <DocsLayout {...props}>{element}</DocsLayout>;
  }
  return element;
};
