import { useEffect, useState } from "react";

const useEscPressed = () => {
  const [escButtonPressed, setEscButtonPressed] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key == "Escape") {
      setEscButtonPressed(true);
    }
  };

  const handleKeyUp = (event) => {
    if (event.key == "Escape") {
      setEscButtonPressed(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.addEventListener("keyup", handleKeyUp);
    };
  }, []);

  return escButtonPressed;
};

export default useEscPressed;
