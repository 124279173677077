import React from "react";
import { Helmet } from "react-helmet";

interface Props {
  /**
   * A font set to preload - each have a set of variants that get loaded
   */
  fontSet: "inter";
}

type FontSet<ValueType> = { [Key in Props["fontSet"]]: ValueType };

/**
 * Where fonts are loaded from - should match the url in _fonts.scss
 */
const BASE_PATH = "/fonts";

/**
 * The extension to use
 */
const FONT_EXTENSION: FontSet<string> = {
  inter: "woff2",
};

/**
 * Defines names of individual font files inside the folders WITHOUT
 * the extension. The folder name must equal the fontSet variable name.
 */
const FONT_FILES: FontSet<string[]> = {
  inter: ["InterRegular", "InterMedium", "InterSemibold"],
};

/**
 * Preloads a given font by adding directives to Helmet. To add/remove new types,
 * change all the variables above. Of note: the name of `fontSet` must be the same
 * as the folder name where the font is housed. It's best to use woff2 files but
 * we have some woff still here.
 */
const PreloadedFont = ({ fontSet }: Props) => (
  <Helmet
    link={FONT_FILES[fontSet].map((fileName) => ({
      rel: "preload",
      href: `${BASE_PATH}/${fontSet}/${fileName}.${FONT_EXTENSION[fontSet]}`,
      as: "font",
      type: `font/${FONT_EXTENSION[fontSet]}`,
      crossOrigin: "anonymous",
    }))}
  />
);

export default PreloadedFont;
