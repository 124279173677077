import { graphql, useStaticQuery } from "gatsby";
import type { OpenAPIV3 } from "openapi-types";
import { useMemo } from "react";
import { APICategory } from "@merge-api/merge-javascript-shared";
import { isApiCategoryEnum } from "../utils/services";

type Nodes<Category extends APICategory> = {
  nodes: {
    /**
     * Which API category this corresponds to
     */
    category: Category;
    /**
     * The schemas get passed to FE as a JSON string
     */
    schema: OpenAPIV3.Document;
  }[];
};

export interface SchemasQueryResult {
  allSchemas: Nodes<APICategory>;
}

export default (): Record<APICategory, OpenAPIV3.Document> => {
  const queryResult = useStaticQuery<SchemasQueryResult>(graphql`
    query {
      allSchemas {
        nodes {
          schema
          category
        }
      }
    }
  `);

  return useMemo(
    () =>
      (queryResult?.allSchemas?.nodes ?? [])
        .filter((node) => isApiCategoryEnum(node.category))
        .reduce(
          (acc, node) => ({
            ...acc,
            [node.category]: node.schema,
          }),
          {} as Record<APICategory, OpenAPIV3.Document>,
        ),
    [queryResult],
  );
};
