import React, { createContext, useState } from "react";

type Hash = string | null;

interface HashContextType {
  /**
   * The current page's hash value
   */
  hash: Hash;

  /**
   * Sometimes we need to update the hash from the current value of `hash` because we've
   * scrolled down the page. But sometimes we need to update the hash from the `location.hash`
   * since we clicked on a link and our current `hash` is outdated.
   *
   * If the Gatsby `Link` element properly triggered `hashchange` when it navigated to a new
   * link, we could use that to know when to update. Unfortunately it doesn't. But the
   * `location` prop's `key` on a page is either "inital" or the last timestamp once we click
   * on a link. By saving the timestamp when we scroll and update the hash, we can determine
   * whether the click or the scroll is newer and thus, which hash value to use.
   *
   * If Reach.Router ever changes how the key is determined for `location`, this will break
   * and clicking on links won't change the page's hash properly. As that package is in
   * maintenance mode, it's unlikely to change.
   */
  lastChangeTimestamp: number;

  /**
   * Allows for setting a new hash. The last timestamp may be a value to update the
   * last change timestamp if this `setHash` comes from a link click. Otherwise, the value
   * will be missing and the function will set it based on `Date.now()`.
   */
  setHash: (hash: Hash, lastTimestamp?: number) => void;
}

/**
 * Creates context that reads the hash value from the current location
 * and provides it to children components.
 */
export const HashContext = createContext<HashContextType>({
  hash: global?.location?.hash.replace("#", ""),
  lastChangeTimestamp: 0,
  setHash: () => {},
});

/**
 * Exposes a way to set the hash when needed - use this instead of the
 * main HashContext.Provider
 */
export const HashProvider = ({ children }: Pick<React.ComponentProps<"div">, "children">) => {
  const [lastChangeTimestamp, setLastChangeTimestamp] = useState<number>(Date.now());
  const [hash, rawSetHash] = useState<Hash>(global?.location?.hash?.replace("#", "") ?? null);

  // Ensure that our hash never has a prefixed #, and that we update the change timestamp when needed
  const setHash = (newHash: Hash, lastTimestamp?: number) => {
    setLastChangeTimestamp(lastTimestamp ?? Date.now());
    rawSetHash(newHash?.replace("#", "") ?? null);
  };

  return (
    <HashContext.Provider value={{ hash, lastChangeTimestamp, setHash }}>
      {children}
    </HashContext.Provider>
  );
};
