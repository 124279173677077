import React from "react";
import styled from "styled-components";
import { Row } from "react-bootstrap";
import { fullDisplayNameForAPICategory } from "components/docs/utils/services";
import { Link } from "gatsby";
import { APICategory } from "@merge-api/merge-javascript-shared";
import DeprecatedH1 from "components/deprecated/DeprecatedH1";
import { StaticImage } from "gatsby-plugin-image";
const IntegrationOverviewPageContainer = styled(Row)`
  && {
    @media (max-width: 767.96px) {
      padding: 40px 4px;
    }
    padding: 40px;
  }
`;

const IntegrationOverviewPageTitle = styled(DeprecatedH1)`
  font-size: 32px;
  font-weight: 500px;
  line-height: 400px;
  color: #080808;
`;

const IntegrationOverviewPageSubtitle = styled.div`
  margin-bottom: 36px;
`;

const StyledHr = styled.hr`
  && {
    border-top: 1px solid var(--gray10);
  }
`;

const IntegrationTileContainer = styled.div`
  margin-top: 36px;
  margin-bottom: 36px;
  display: flex;
  flex-wrap: wrap;
`;

const IntegarationTile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 2px 8px;
  gap: 4px;
  margin-right: 12px;
  margin-bottom: 16px;
  width: 120px;
  height: 110px;
  box-shadow: 0px 1px 8px -2px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  &:hover {
    background-color: #f2f4f7;
  }
`;

const Icon = styled.img`
  border-radius: 40px;
  width: 40px;
  height: 40px;
`;

export const IconContainer = styled.div`
  border-radius: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
`;

const IntegrationName = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #000000;
`;

export type IntegrationInfo = {
  slug: string;
  square_image: string;
  name: string;
  abbreviated_name: string;
};

export type Integration = {
  integration: IntegrationInfo;
};

interface IntegrationOverviewPageProps {
  pageContext: {
    key: APICategory;
    integrations: {
      integration: Integration;
    };
  };
}

const IntegrationOverviewPage = ({ pageContext }: IntegrationOverviewPageProps) => {
  const { key, integrations } = pageContext;
  const categoryDisplayName = fullDisplayNameForAPICategory(key);

  return (
    <IntegrationOverviewPageContainer className="w-100 d-flex flex-column mx-auto">
      <IntegrationOverviewPageTitle>Overview</IntegrationOverviewPageTitle>
      <IntegrationOverviewPageSubtitle>
        See all the {categoryDisplayName} platforms you can integrate with through Merge.
      </IntegrationOverviewPageSubtitle>
      <StyledHr className="m-0" />
      <IntegrationTileContainer>
        {Object.values(integrations).map((integration) => {
          return (
            <Link
              to={`/integrations/${key}/${integration.integration.slug}/`}
              activeClassName="active"
            >
              <IntegarationTile>
                {integration.integration.name == "Gusto" ? (
                  <IconContainer>
                    <StaticImage src="../../../assets/images/gusto_generic.png" alt="" />
                  </IconContainer>
                ) : (
                  <Icon src={integration.integration.square_image} />
                )}
                <IntegrationName>
                  {integration.integration.abbreviated_name || integration.integration.name}
                </IntegrationName>
              </IntegarationTile>
            </Link>
          );
        })}
      </IntegrationTileContainer>
    </IntegrationOverviewPageContainer>
  );
};
export default IntegrationOverviewPage;
