/**
 * Removes the slash at the end of the path if present and returns the
 * new string.
 */
export const removeTrailingSlash = (path: string): string => {
  if (path.length > 0 && path.endsWith("/")) {
    return path.substring(0, path.length - 1);
  }
  return path;
};
