import { createGlobalStyle } from "styled-components";
import { spectrum } from "./theme";

const GlobalStyle = createGlobalStyle`
  :root {
    --gray0: ${spectrum.gray0};
    --gray10: ${spectrum.gray10};
    --gray20: ${spectrum.gray20};
    --gray30: ${spectrum.gray30};
    --gray40: ${spectrum.gray40};
    --gray50: ${spectrum.gray50};
    --gray60: ${spectrum.gray60};
    --gray70: ${spectrum.gray70};
    --gray80: ${spectrum.gray80};
    --gray90: ${spectrum.gray90};

    --red0: ${spectrum.red0};
    --red10: ${spectrum.red10};
    --red20: ${spectrum.red20};
    --red30: ${spectrum.red30};
    --red40: ${spectrum.red40};
    --red50: ${spectrum.red50};
    --red60: ${spectrum.red60};
    --red70: ${spectrum.red70};
    --red80: ${spectrum.red80};
    --red90: ${spectrum.red90};

    --yellow0: ${spectrum.yellow0};
    --yellow10: ${spectrum.yellow10};
    --yellow20: ${spectrum.yellow20};
    --yellow30: ${spectrum.yellow30};
    --yellow40: ${spectrum.yellow40};
    --yellow50: ${spectrum.yellow50};
    --yellow60: ${spectrum.yellow60};
    --yellow70: ${spectrum.yellow70};
    --yellow80: ${spectrum.yellow80};
    --yellow90: ${spectrum.yellow90};

    --green0: ${spectrum.green0};
    --green10: ${spectrum.green10};
    --green20: ${spectrum.green20};
    --green30: ${spectrum.green30};
    --green40: ${spectrum.green40};
    --green50: ${spectrum.green50};
    --green60: ${spectrum.green60};
    --green70: ${spectrum.green70};
    --green80: ${spectrum.green80};
    --green90: ${spectrum.green90};

    --blue0: ${spectrum.blue0};
    --blue10: ${spectrum.blue10};
    --blue20: ${spectrum.blue20};
    --blue30: ${spectrum.blue30};
    --blue40: ${spectrum.blue40};
    --blue50: ${spectrum.blue50};
    --blue60: ${spectrum.blue60};
    --blue70: ${spectrum.blue70};
    --blue80: ${spectrum.blue80};
    --blue90: ${spectrum.blue90};

    --indigo0: ${spectrum.indigo0};
    --indigo10: ${spectrum.indigo10};
    --indigo20: ${spectrum.indigo20};
    --indigo30: ${spectrum.indigo30};
    --indigo40: ${spectrum.indigo40};
    --indigo50: ${spectrum.indigo50};
    --indigo60: ${spectrum.indigo60};
    --indigo70: ${spectrum.indigo70};
    --indigo80: ${spectrum.indigo80};
    --indigo90: ${spectrum.indigo90};

    --purple0: ${spectrum.purple0};
    --purple10: ${spectrum.purple10};
    --purple20: ${spectrum.purple20};
    --purple30: ${spectrum.purple30};
    --purple40: ${spectrum.purple40};
    --purple50: ${spectrum.purple50};
    --purple60: ${spectrum.purple60};
    --purple70: ${spectrum.purple70};
    --purple80: ${spectrum.purple80};
    --purple90: ${spectrum.purple90};

    --pink0: ${spectrum.pink0};
    --pink10: ${spectrum.pink10};
    --pink20: ${spectrum.pink20};
    --pink30: ${spectrum.pink30};
    --pink40: ${spectrum.pink40};
    --pink50: ${spectrum.pink50};
    --pink60: ${spectrum.pink60};
    --pink70: ${spectrum.pink70};
    --pink80: ${spectrum.pink80};
    --pink90: ${spectrum.pink90};
  }
`;

export default GlobalStyle;
